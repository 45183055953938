const giftVoucherManageCreate = (ajaxinstance) => {
  const giftVoucherManage = {}
  const header = {
    headers: {
        'content-Type':'application/json; charset=UTF-8'
    }
 }
   //礼券分类 列表
  giftVoucherManage.cardQueryList = (payload) => {
      return ajaxinstance.post(`cms/cardCategory/queryList`,  payload ,{
        ...header
      })
  }
   //礼券分类 新增
  giftVoucherManage.cardSave = (payload) => {
      return ajaxinstance.post(`cms/cardCategory/saveCardCategory`,  payload ,{
        ...header
      })
  }
  //礼券分类 删除 
  giftVoucherManage.cardDelete = (payload) => {
      return ajaxinstance.post(`cms/cardCategory/deleteCardCategoty`,  payload ,{
        ...header
      })
  }
  //礼券列表 列表
  giftVoucherManage.queryCardList = (payload) => {
      return ajaxinstance.post(`cms/card/queryCardList`,  payload ,{
        ...header
      })
  }
  //礼券列表 新增
  giftVoucherManage.saveCard = (payload) => {
      return ajaxinstance.post(`cms/card/saveCard`,  payload ,{
        ...header
      })
  }
  //礼券列表 详情
  giftVoucherManage.getCard = (payload) => {
      return ajaxinstance.post(`cms/card/getCard`,  payload ,{
        ...header
      })
  }
  //礼券列表 发放详情
  giftVoucherManage.getCardInfo = (payload) => {
      return ajaxinstance.post(`cms/card/getCardInfo`,  payload ,{
        ...header
      })
  }
  //礼券列表 发放详情  列表
   giftVoucherManage.fuzzyQueryCardMember = (payload) => {
      return ajaxinstance.post(`cms/cardMember/fuzzyQueryCardMember`,  payload ,{
        ...header
      })
  }
  //礼券列表 批量过期和批量使用
  giftVoucherManage.updateCardInfo = (payload) => {
      return ajaxinstance.post(`cms/cardMember/operateCardMemberList`,  payload ,{
        ...header
      })
  }
  //礼券列表  获取使用门店信息 
  giftVoucherManage.getPOSInfoByCardIdAndStoreId = (payload) => {
      return ajaxinstance.post(`cms/card/getStoreCity`,  payload ,{
        ...header
      })
  }
  //礼券列表 添加礼券 
  giftVoucherManage.saveStoreIds = (payload) => {
      return ajaxinstance.post(`cms/card/saveStoreIds`,  payload ,{
        ...header
      })
  }
  //礼券列表 删除or关闭 
  giftVoucherManage.updateCard = (payload) => {
      return ajaxinstance.post(`cms/card/updateCard`,  payload ,{
        ...header
      })
  }
   //礼券列表 编辑
   giftVoucherManage.edit = (payload) => {
      return ajaxinstance.post(`cms/card/edit`,  payload ,{
        ...header
      })
  }
  return {
      giftVoucherManage
  }
}
export default giftVoucherManageCreate

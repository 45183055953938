// 有关时间日期的转换
function timestamp(text){//时间戳转为日期
    let timestamp4 = new Date(parseInt(text) * 1000);//直接用 new Date(时间戳) 格式转化获得当前时间
    let date = timestamp4.toLocaleDateString().replace(/\//g, "-") + " " + timestamp4.toTimeString().substr(0, 8)
     return date
}
function datesToStamp(text){//日期转为时间戳
    let str=text.replace(/-/g,"/")
    let dates = new Date(str);
    let time3 = Date.parse(dates);
    time3=time3/1000;
    return time3
}
export default {
    timestamp,
    datesToStamp
}

import React from 'react'
import PropTypes from 'prop-types'
import { api } from '@/common/app'
import { Row, Form, Icon, Input, Button } from 'antd';
import qs from 'qs'
import md5 from 'md5'
import './login.less'
const FormItem = Form.Item
class Redirect extends React.Component {
  constructor(prop) {
    super(prop)
    this.state = {
      code: '',
      session: '',
    }
  }
  componentWillMount() {
    const url = window.location.href
    // const url = 'https://t.revital-cosmetics.cn/pg/?code=0.DAIAD8JR6oMmhkWEXHb6thhJFpchx4eeKsBEmGDmwKQooRoBAAA.AQABBAIAAAAbFHB008SuTqbyrjDMnNWbgJ8Ti_qCCP0jXd50QPhmvbnx3jDklPmoTgv1rrMvVSWpQqrKMY-Lfli-YPwuL1zQSQkyiIgperoHWuRjhVYKielEpGWywGQUgd8Th5rWs5rmcMFsRfQn3nEVPuNg09LE8U2VbOh85x1em37ZUhZDVMZhUhcKPuUctliEn6cHyrTjTF4EVo9R1kxRSGCej2ZdU0FDrQUlV9OHbbWD8rs4bWhIfCX5BddTYvro0nFeSG1CFJbocDxjfE7wSdbNNXDVeCc4IWv4gAVUz7zjh4Gt9ZK6AkLkGUoTmtmo_Ney_YFApOgw4PGkJcB6ovL4jpfDAiem4gop8N82T1WDKYOqAKHuYCiILBITSf9-CQ5X9KwYHX3Vx0VAVwFXGsb54-mI4HHvJZtWRxzTjRlXQoq86oQ8MMbt_Hx0UJAzeEak3QO33XEJhSk16Z2ak8BChPU2AMOF96Hlz75mkqBPg1-5Ygel2PxNnBd61B8M7RfyBC9szNOu5lYcPk1_Q3uykHjQ8qnLSU6w7lfjpmzWTUHBphxeTwOdBc1uKtCMZpVUXH_h5jua6F9-Fm9QT6Ft62n7Z8PKwSc4yteoiLPjaetRS0llZyDJlPMjlm7SZwSgD4MgAA&session_state=15c0b863-104b-422e-ad66-73777cfdacdf#/'
    const codeUrl = url.split('#');
    const urlState = codeUrl[0].split('?');
    const para = urlState[1].split('&')
    const code = para[0].split('=')[1]
    const session = para[1].split('=')[1]
    console.log(code, session)
    this.setState({
      code: code,
      session: session
    })
  }
  handleSubmit = () => {
    const {code} = this.state
    let values = {
      code:code
    }
    const params=qs.stringify(values)
    console.log(this.state)
    api
      .customer
      .login(params)
      .then(res => {
        if (res && res.errorCode == 0) {
          window.localStorage.setItem('username', res.data.nickName)
          window.localStorage.setItem('userId', res.data.userId)
          window.localStorage.setItem('dataType', res.data.role.dataType)
          this.props.history.push('/home')
        }
      })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (<div className='login-page'>
      <Form className="login-form">
        <FormItem>
          <Button type="primary" onClick={this.handleSubmit} className="login-form-button">
            登录
          </Button>
        </FormItem>
      </Form>
    </div>
    )
  }
}

Redirect.propTypes = {
  form: PropTypes.object.isRequired,
  history: PropTypes.object
}

export default Form.create()(Redirect)

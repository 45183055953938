import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const BindStoreAudits = Loadable({
  loader: () => import('./bind-store-audits'),
  loading: Loading
})
export default (
  <React.Fragment>
    <Route exact path='/BindStoreAudits' component={BindStoreAudits} />
  </React.Fragment>
)

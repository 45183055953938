import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const DiscoveryList = Loadable({
  loader: () => import('./discovery-list'),
  loading: Loading
})
const SpecialShowManage = Loadable({
    loader: () => import('./special-show-manage'),
    loading: Loading
})
const TopSearchManage = Loadable({
    loader: () => import('./top-search-manage'),
    loading: Loading
})
const LabelManage = Loadable({
    loader: () => import('./label-manage'),
    loading: Loading
})
const CommentManage = Loadable({
    loader: () => import('./comment-manage'),
    loading: Loading
})
const AuditManage = Loadable({
    loader: () => import('./audit-manage'),
    loading: Loading
})
const ReportManage = Loadable({
    loader: () => import('./report-manage'),
    loading: Loading
})
const SensitiveWordManage = Loadable({
    loader: () => import('./sensitive-word-manage'),
    loading: Loading
})
export default (
  <React.Fragment>
    <Route path='/DiscoveryList' component={DiscoveryList} />
    <Route path='/TopSearchManage' component={TopSearchManage} />
    <Route path='/LabelManage' component={LabelManage} />
    <Route path='/CommentManage' component={CommentManage} />
    {/* <Route path='/AuditManage' component={AuditManage} />   //这个不要的*/}
    <Route path='/SpecialShowManage' component={SpecialShowManage} />
    <Route path='/ReportManage' component={ReportManage} />
    <Route path='/SensitiveWordManage' component={SensitiveWordManage} />
  </React.Fragment>
)

import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const StoreList = Loadable({
  loader: () => import('./store-list'),
  loading: Loading
})
const ImportStores = Loadable({
  loader: () => import('./import-stores'),
  loading: Loading
})
const StoreBasicData = Loadable({
  loader: () => import('./store-basic-data'),
  loading: Loading
})
const BasicDataType = Loadable({
  loader: () => import('./basic-data-type'),
  loading: Loading
})
export default (
  <React.Fragment>
    <Route path='/StoreList' component={StoreList} />
    <Route path='/ImportStores' component={ImportStores} />
    <Route path='/StoreBasicData' component={StoreBasicData} />
    <Route path='/BasicDataType' component={BasicDataType} />
  </React.Fragment>
)

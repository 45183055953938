import config from '../utils/config'
const enjoyMeetingCreate = (ajaxinstance) => {
    let enjoyMeetingManage = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
    }
     // 反馈列表
     enjoyMeetingManage.queryUTaskList = (payload) => {
        return ajaxinstance.post(`cms/uTask/queryUTaskList`,payload,{
            ...header
        })
    }
    //完成任务信息
    enjoyMeetingManage.queryUTaskCompleteList = (payload) => {
        return ajaxinstance.post(`cms/uTaskCompleteInfo/queryUTaskCompleteList`,payload,{
            ...header
        })
    }
    //感谢礼管理  列表
    enjoyMeetingManage.queryThanksGiftList = (payload) => {
        return ajaxinstance.post(`cms/thanksGift/queryThanksGiftList`,payload,{
            ...header
        })
    }
     //感谢礼管理 详情
     enjoyMeetingManage.operateThanksGiftById = (payload) => {
        return ajaxinstance.post(`cms/thanksGift/operateThanksGiftById`,payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
    //感谢礼管理 删除
    enjoyMeetingManage.operateThanksGiftDelete = (payload) => {
        return ajaxinstance.post(`cms/thanksGift/operateThanksGiftDelete`,payload,{
            ...header
        })
    }
    //感谢礼管理 新增
    enjoyMeetingManage.operateThanksGiftSave = (payload) => {
        return ajaxinstance.post(`cms/thanksGift/operateThanksGiftSave`,payload,{
            ...header
        })
    }
    //礼券列表 列表
    enjoyMeetingManage.queryCardListValid = (payload) => {
        return ajaxinstance.post(`cms/card/queryCardListValid`,  payload ,{
          ...header
        })
    }
    //感谢礼管理 编辑
    enjoyMeetingManage.operateThanksGiftUpdate = (payload) => {
        return ajaxinstance.post(`cms/thanksGift/operateThanksGiftUpdate`,payload,{
            ...header
        })
    }
    //感谢礼管理 上下架
    enjoyMeetingManage.operateThanksGiftUpDown = (payload) => {
        return ajaxinstance.post(`cms/thanksGift/operateThanksGiftUpDown`,payload,{
            ...header
        })
    }
    // 感谢礼预约管理  列表
    enjoyMeetingManage.queryThanksGiftOrderList = (payload) => {
        return ajaxinstance.post(`cms/thanksGiftOrder/queryThanksGiftOrderList`,payload,{
            ...header
        })
    }
     // 感谢礼预约管理  详情
     enjoyMeetingManage.operateThanksGiftOrderById = (payload) => {
        return ajaxinstance.post(`cms/thanksGiftOrder/operateThanksGiftOrderById`,payload,{
            ...header
        })
    }
     // 感谢礼预约管理  取消预约
     enjoyMeetingManage.operateThanksGiftOrderCancel = (payload) => {
        return ajaxinstance.post(`cms/thanksGiftOrder/operateThanksGiftOrderCancel`,payload,{
            ...header
        })
    }
     // 感谢礼预约管理  预约备注
     enjoyMeetingManage.operateThanksGiftOrderRemarkSave = (payload) => {
        return ajaxinstance.post(`cms/thanksGiftOrderRemark/operateOrderRemarkSave`,payload,{
            ...header
        })
    }
     // 感谢礼预约管理  发货
     enjoyMeetingManage.operateThanksGiftOrderDeliver = (payload) => {
        return ajaxinstance.post(`cms/thanksGiftOrder/operateThanksGiftOrderDeliver`,payload,{
            ...header
        })
    }
     // 感谢礼预约管理  批量发货
     enjoyMeetingManage.operateThanksGiftOrderListDeliver = (payload) => {
        return ajaxinstance.post(`cms/thanksGiftOrder/operateThanksGiftOrderListDeliver`,payload,{
            ...header
        })
    }
    // 感谢礼预约管理/批量发货  列表
    enjoyMeetingManage.queryThanksGiftOrderDeliverList = (payload) => {
        return ajaxinstance.post(`cms/thanksGiftOrder/queryThanksGiftOrderDeliverList`,payload,{
            ...header
        })
    }
    // 感谢礼预约管理/批量发货  查询门店详细信息
    enjoyMeetingManage.queryStoreByNameOrCode = (payload) => {
        return ajaxinstance.post(`cms/store/queryStoreByNameOrCode`,payload,{
            ...header
        })
    }
     // 感谢礼预约管理  编辑领取人
     enjoyMeetingManage.operateThanksGiftReceiverUpdate = (payload) => {
        return ajaxinstance.post(`cms/thanksGiftOrder/operateThanksGiftReceiverUpdate`,payload,{
            ...header
        })
    }
    // 感谢礼预约管理  打印
    enjoyMeetingManage.operateThanksGiftOrderPrint = (payload) => {
        return ajaxinstance.post(`cms/thanksGiftOrder/operateThanksGiftOrderPrint`,payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
    // 感谢礼预约管理/批量发货  打印
    enjoyMeetingManage.operateThanksGiftOrderAllPrint = (payload) => {
        return ajaxinstance.post(`cms/thanksGiftOrder/operateThanksGiftOrderAllPrint`,payload,{
            ...header
        })
    }
    // 会员试用计划 列表
     enjoyMeetingManage.operateMemberTrialSampleListByFilter = (payload) => {
        return ajaxinstance.post(`cms/membertrialsample/operateMemberTrialSampleListByFilter`,payload,{
            ...header
        })
    }
    // 会员试用计划 添加
    enjoyMeetingManage.operateMemberTrialSampleSaveOrUpdate = (payload) => {
        return ajaxinstance.post(`cms/membertrialsample/operateMemberTrialSampleSaveOrUpdate`,payload,{
            ...header
        })
    }
     // 会员试用计划 上下架
     enjoyMeetingManage.operateMemberTrialSampleIsUp = (payload) => {
        return ajaxinstance.post(`cms/membertrialsample/operateMemberTrialSampleIsUp`,payload,{
            ...header
        })
    }
    // 会员试用计划 删除
    enjoyMeetingManage.operateMemberTrialSampleStatus = (payload) => {
        return ajaxinstance.post(`cms/membertrialsample/operateMemberTrialSampleStatus`,payload,{
            ...header
        })
    }
     // 会员试用计划 详情
     enjoyMeetingManage.operateMemberTrialSampleInfoById = (payload) => {
        return ajaxinstance.post(`cms/membertrialsample/operateMemberTrialSampleInfoById`,payload,{
            ...header
        })
    }
    // 会员试用计划 设置各个等级的会员的会员试用计划的领取次数数据信息回显
    enjoyMeetingManage.getSetNumber = (payload) => {
        return ajaxinstance.post(`cms/membertrialsamplecount/operateMemberTrialSampleCountDataInformationEcho`,payload,{
            ...header
        })
    }
    // 会员试用计划 保存各个等级的会员的会员试用计划
    enjoyMeetingManage.setNumberSave = (payload) => {
        return ajaxinstance.post(`cms/membertrialsamplecount/operateMemberTrialSampleCount`,payload,{
            ...header
        })
    }
    // 会员试用计划 会员试用计划用户领取列表
    enjoyMeetingManage.operateMemberTrialRecordOrderLists = (payload) => {
        return ajaxinstance.post(`cms/membertrialrecord/operateMemberTrialRecordOrderLists`,payload,{
            ...header
        })
    }
    // 来店礼遇  领取记录列表
    enjoyMeetingManage.operateToStoreListByFilter = (payload) => {
        return ajaxinstance.post(`cms/toStoreCard/operateToStoreListByFilter`,payload,{
            ...header
        })
    }
    // 来店礼遇  暂停领取
    enjoyMeetingManage.operateToStoreStopGet = (payload) => {
        return ajaxinstance.post(`cms/toStoreCard/operateToStoreStopGet`,payload,{
            ...header
        })
		}
		//查询敏感词
		enjoyMeetingManage.queryDiscoverySensitiveList = (payload) => {
			return ajaxinstance.post(`cms/discoverySensitive/queryDiscoverySensitiveList`,payload,{
				...header
			})
		}
		enjoyMeetingManage.operateDiscoverySensitiveSave = (payload) => {
			return ajaxinstance.post(`cms/discoverySensitive/operateDiscoverySensitiveSave`, payload, {
				...header
			})
		}
		//删除敏感词
		enjoyMeetingManage.operateDiscoverySensitiveDelete = (payload) => {
			return ajaxinstance.post(`cms/discoverySensitive/operateDiscoverySensitiveDelete`, payload, {
				...header
			})
		}
		//导出敏感词
		enjoyMeetingManage.operateDiscoverySensitiveExport = (payload) => {
			return ajaxinstance.get(`cms/discoverySensitive/operateDiscoverySensitiveExport`, payload, {
				...header
			})
		}
		enjoyMeetingManage.operateDiscoverySpecialIsTop = (payload) => {
			return ajaxinstance.post(`cms/discoverySpecial/operateDiscoverySpecialIsTop`, payload, {
				 headers: {
				 	'content-Type': 'application/x-www-form-urlencoded'
				 }
			})
		}
    return {
        enjoyMeetingManage
    }
  }
  export default enjoyMeetingCreate

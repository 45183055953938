
function getCurrentDate () {
  return new Date()
};
function getPriorMonthFirstDay (year, month) {
  if (+month === 0) {
    month = 11// 月份为上年的最后月份
    year--// 年份减1
    return new Date(year, month, 1)
  }
  month--
  return new Date(year, month, 1)
};
function getMonthDays (year, month) {
  // 本月第一天 1-31
  var relativeDate = new Date(year, month, 1)
  // 获得当前月份0-11
  var relativeMonth = relativeDate.getMonth()
  // 获得当前年份4位年
  var relativeYear = relativeDate.getFullYear()

  // 当为12月的时候年份需要加1
  // 月份需要更新为0 也就是下一年的第一个月
  if (+relativeMonth === 11) {
    relativeYear++
    relativeMonth = 0
  } else {
    // 否则只是月份增加,以便求的下一月的第一天
    relativeMonth++
  }
  // 一天的毫秒数
  var millisecond = 1000 * 60 * 60 * 24
  // 下月的第一天
  var nextMonthDayOne = new Date(relativeYear, relativeMonth, 1)
  // 返回得到上月的最后一天,也就是本月总天数
  return new Date(nextMonthDayOne.getTime() - millisecond).getDate()
};
export default function lastMonthDay () {
  // 起止日期数组
  var startStop = []
  // 获取当前时间
  var currentDate = getCurrentDate()
  // 获得当前月份0-11
  var currentMonth = currentDate.getMonth()
  // 获得当前年份4位年
  var currentYear = currentDate.getFullYear()
  // 获得上一个月的第一天
  var priorMonthFirstDay = getPriorMonthFirstDay(currentYear, currentMonth)
  // 获得上一月的最后一天
  var priorMonthLastDay = new Date(
    priorMonthFirstDay.getFullYear(),
    priorMonthFirstDay.getMonth(),
    getMonthDays(priorMonthFirstDay.getFullYear(),
      priorMonthFirstDay.getMonth()))
  // 添加至数组
  startStop.push(priorMonthFirstDay.toLocaleDateString())
  startStop.push(priorMonthLastDay.toLocaleDateString())
  return startStop
};

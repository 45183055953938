import React from 'react'
import './home.less'

class Home extends React.Component {
  // componentDidMount(){
  //   this.toVoid(1)
  // }
  // toVoid = (id)=>{
  //   const params = qs.stringify({modelId:id})
  //   const that = this
  //   console.log(params)
  //   confirm({
  //     title:'确认作废该产品下的二维码吗？',
  //     okText:'确定',
  //     cancelText:'取消',
  //     onOk(){
  //       api
  //         .barCodeManage
  //         .operateCreateStateByGive(params)
  //         .then(res=>{
  //           if(res.errorCode == 0){
  //             message.success("操作成功")
  //             that.loadData()
  //           }
  //         })
  //     }
  //   })
  // }
  render () {
    return <div className="home">
      <div className='welcome'>欢迎使用CRM管理系统</div>
    </div>
  }
}
export default Home

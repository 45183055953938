import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "../styles/app.css";
import Router from "./router";

// 特殊页面
import redirect from "./redirect";
// 登录
import Login from "./login";
// 首页
import Home from "./home";
// 会员管理
import memberMessage from "./member-message";
// 条码管理
import barCodeMessage from "./barCode-message";
// 门店管理
import storeManege from "./store-manage";
// 产品信息同步
import productInfoSync from "./product-info-sync";
// 礼券管理
import giftVoucherManage from "./gift-voucher-manage";
// 意见反馈管理
import feedbackManage from './feedback-manage';
// 消息管理
import messageManage from './message-manage';
// 绑定门店审核
import bindStoreAudits from './bind-store-audits';
// 悠享会管理
// import enjoyMeetingMessage from "./enjoy-meeting-message";
// 商品管理
import goodsMessage from "./goods-message";
// 兑换管理
// import exchangeMessage from "./exchange-message";
// 订单管理
import orderManage from './order-manage';
// 商城
import mallManage from './mall-manage';
//活动管理
import activityManage from './activity-manage';
//首页管理
import mainManage from './main-manage';
//新品管理
import newProductManage from './new-product-manage';
//礼品兑换管理
import giftRedemptionManage from './gift-redemption-manage'
//小程序管理
import weChatAppletManage from './weChat-applet-manage'
// import enjoyManage from './enjoy-manage'
//发现管理
import discoveryManage from './discovery-manage'
//门店核销 管理 
import storeReservation  from './store-reservation'
//日志 管理
import WorkLog  from './work-log'

//管理员管理  
import adminManage  from './admin-manage'
const routes = () => (
  <HashRouter>
    <Switch>
      {/* <Route exact path="" component={Login} /> */}
      <Route exact path="/" component={Login} />
      <Route exact path="/redirect" component={redirect} />
      <Route
        path="/"
        component={({ match }) => {
          return (
            <Router>
              <div>
                <Route path="/home" component={Home} />
                {memberMessage}
                {barCodeMessage}
                {/* {enjoyMeetingMessage} */}
                {goodsMessage}
                {/* {exchangeMessage} */}
                {storeManege}
                {productInfoSync}
                {giftVoucherManage}
                {bindStoreAudits}
                {feedbackManage}
                {orderManage}
                {mallManage}
                {messageManage}
                {activityManage}
                {mainManage}
                {newProductManage}
                {giftRedemptionManage}
                {weChatAppletManage}
                {discoveryManage}
                {/* {enjoyManage} */}
                {storeReservation}
                {WorkLog}
                {adminManage}
              </div>
            </Router>
          );
        }}
      />
    </Switch>
  </HashRouter>
);

export default routes;

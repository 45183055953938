import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const GoodsList = Loadable({
  loader: () => import('./goods-list'),
  loading: Loading
})
const GoodsGroup = Loadable({
    loader: () => import('./goods-group'),
    loading: Loading
  })
export default (
  <React.Fragment>
    <Route exact path='/GoodsList' component={GoodsList} />
    <Route exact path='/GoodsGroup' component={GoodsGroup} />
  </React.Fragment>
)

const barCodeCreate = (ajaxinstance) => {
    let barCodeManage = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
    }
     // 条码列表
     barCodeManage.barCodeList = (payload) => {
        return ajaxinstance.post(`cms/create/list`,payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
    //条码详情列表
    barCodeManage.barDetailCodeList = (pageSize,pageNum,createId,url,codeState) => {
        return ajaxinstance.get(`cms/code/list?pageSize=${pageSize}&pageNum=${pageNum}&createId=${createId}&url=${url}&codeState=${codeState}`)
    }
    //详情单条作废
    barCodeManage.operateCodeGiveById = (modelId) => {
        return ajaxinstance.get(`cms/code/operateCodeGiveById?modelId=${modelId}`)
    }
    //列表作废
    barCodeManage.operateCreateStateByGive = (payload) => {
        return ajaxinstance.post(`cms/create/operateCreateStateByGive`,payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
     //生成条码  根据code获取详情  
     barCodeManage.getProductInfo = (payload) => {
        return ajaxinstance.post(`cms/product/getProductInfo`, payload,{
            headers: {
                'content-Type':'application/x-www-form-urlencoded'
              }
        })
    }
    //生成code  method  post cms/create/saveMoreCreate
    barCodeManage.saveMoreCreate = (payload) => {
        return ajaxinstance.post(`cms/create/saveMoreCreate`, payload,{
            ...header
        })
    }
    return {
        barCodeManage
    }
  }
  export default barCodeCreate
  
const mainCreate = (ajaxinstance) => {
    const mainManage = {}
    const header = {
      headers: {
          'content-Type':'application/json; charset=UTF-8'
      }
   }
   const headerForm = {
    headers:{
      'content-Type':'application/x-www-form-urlencoded'
  }
 }
     //banner管理 列表
    mainManage.queryList = (payload) => {
      return ajaxinstance.post(`cms/wxbanner/queryList`, payload,{
        ...header
      })
    }
   //banner管理 排序
   mainManage.sortWXbanner = (payload) => {
      return ajaxinstance.post(`cms/wxbanner/sortWXbanner`, payload,{
        ...header
      })
  }
  //banner管理 上下架
  mainManage.downWXbanner = (payload) => {
      return ajaxinstance.post(`cms/wxbanner/downWXbanner`, payload,{
        ...header
      })
  }
   //banner管理 新增
   mainManage.addWXbanner = (payload) => {
      return ajaxinstance.post(`cms/wxbanner/addWXbanner`, payload,{
          ...header
    })
  }
   //banner管理 编辑
   mainManage.getBannerById = (payload) => {
      return ajaxinstance.post(`cms/wxbanner/getBannerById`, payload,{
        headers:{
          'content-Type':'application/x-www-form-urlencoded'
      }
    })
  }
  // banner管理 删除
  mainManage.deleteWXbanner = (payload) => {
      return ajaxinstance.post(`cms/wxbanner/deleteWXbanner`, payload,{
        headers:{
          'content-Type':'application/x-www-form-urlencoded'
      }
    })
  }
  // app 启动页列表
  mainManage.queryBootPageList = (payload) => {
    return ajaxinstance.post(`cms/bootPage/queryBootPageList`, payload,{
      ...header
  })
}
// app 启动页add
mainManage.bootPageSave = (payload) => {
  return ajaxinstance.post(`cms/bootPage/save`, payload,{
    ...header
})
}
// app 启动页删除
mainManage.deleteBootPage = (payload) => {
  return ajaxinstance.post(`cms/bootPage/deleteBootPage`, payload,{
    ...header
})
}
// app 启动页编辑
mainManage.editBootPage = (payload) => {
  return ajaxinstance.post(`cms/bootPage/editBootPage`, payload,{
    ...header
})
}
// app 启动页上下架
mainManage.shelvesBootPage = (payload) => {
  return ajaxinstance.post(`cms/bootPage/shelvesBootPage`, payload,{
    ...header
})
}
// app 启动页排序
mainManage.sortBootPage = (payload) => {
  return ajaxinstance.post(`cms/bootPage/sortBootPage`, payload,{
    ...header
})
}
   //热门商品管理
   mainManage.detonationList = (payload) => {
    return ajaxinstance.post(`cms/detonation/queryList`, payload,{
      ...header
    })
    }
     //热门商品 上下架
     mainManage.shelvesDetonation = (payload) => {
      return ajaxinstance.post(`cms/detonation/shelvesDetonation`, payload,{
        ...headerForm
      })
    }
   //热门商品保存编辑
   mainManage.saveOrUpdate = (payload) => {
    return ajaxinstance.post(`cms/detonation/saveOrUpdate`, payload,{
      ...header
    })
  }
     //热门商品排序
     mainManage.sortDetonation = (payload) => {
      return ajaxinstance.post(`cms/detonation/sortDetonation`, payload,{
        ...header
      })
    }
       //热门商品删除
   mainManage.deleteDetonation = (payload) => {
    return ajaxinstance.post(`cms/detonation/deleteDetonation`, payload,{
      ...headerForm
    })
  }
    //热门商品查询
  mainManage.getDetonationById = (payload) => {
    return ajaxinstance.post(`cms/detonation/getDetonationById`, payload,{
      ...headerForm
    })
  }
  //热门内容  列表
   mainManage.hotcontentList = (payload) => {
    return ajaxinstance.post(`cms/hotcontent/queryList`, payload,{
      ...header
    })
  }
  //热门内容 上下架
   mainManage.operateHotContent = (payload) => {
      return ajaxinstance.post(`cms/hotcontent/operateHotContent`, payload,{
        ...headerForm
      })
    }
  //热门内容保存编辑
   mainManage.hotContentSaveOrUpdate = (payload) => {
    return ajaxinstance.post(`cms/hotcontent/saveOrUpdate`, payload,{
      ...header
    })
  }
  //热门内容排序
  mainManage.sortHotContent = (payload) => {
    return ajaxinstance.post(`cms/hotcontent/sortHotContent`, payload,{
      ...header
    })
  }
  // //热门内容删除
  //  mainManage.hotoperateHotContent = (payload) => {
  //   return ajaxinstance.post(`cms/hotcontent/operateHotContent`, payload,{
  //     ...headerForm
  //   })
  // }
  //热门内容  详情
    mainManage.hotContentGetDetonationById = (payload) => {
        return ajaxinstance.post(`cms/hotcontent/getHotContentInfoById`, payload,{
          ...headerForm
        })
      }
  //最新产品  列表
   mainManage.newestgoodsList = (payload) => {
    return ajaxinstance.post(`cms/newestgoods/queryList`, payload,{
      ...header
    })
  }
    //最新产品分类信息列表
    mainManage.getNewestGoodsByGoodsType = (payload) => {
      return ajaxinstance.post(`cms/newestgoods/getNewestGoodsByGoodsType`, payload,{
        ...header
      })
    }
       //最新产品编辑添加
   mainManage.newsaveOrUpdate = (payload) => {
    return ajaxinstance.post(`cms/newestgoods/saveOrUpdate`, payload,{
      ...header
    })
    }
     //最新产品排序
     mainManage.newsortDetonation = (payload) => {
      return ajaxinstance.post(`cms/newestgoods/sortDetonation`, payload,{
        ...header
      })
    }
       //最新产品上下架
       mainManage.newshelvesDetonation = (payload) => {
        return ajaxinstance.post(`cms/newestgoods/shelvesDetonation`, payload,{
          ...headerForm
        })
      }
  //最新产品删除
   mainManage.newdeleteDetonation = (payload) => { 
    return ajaxinstance.post(`cms/newestgoods/deleteDetonation`, payload,{
      ...headerForm
    })
  }
    //最新产品查询
    mainManage.newgetDetonationById = (payload) => {
    return ajaxinstance.post(`cms/newestgoods/getDetonationById`, payload,{
      ...headerForm
    })
  }
  // 最新产品分类 分类数据
  mainManage.newestgoodstypeQuerylist = (payload) => {
    return ajaxinstance.post(`cms/newestgoodstype/querylist`, payload,{
      ...header
    })
  }
   // 最新产品分类 分类列表
   mainManage.getGoodsTypeList = (payload) => {
    return ajaxinstance.post(`cms/newestgoodstype/getGoodsTypeList`, payload,{
      ...header
    })
  }
  // 最新产品分类 删除
  mainManage.getGoodsTypeDeleteById = (payload) => {
    return ajaxinstance.post(`cms/newestgoodstype/deleteById`, payload,{
      ...header
    })
  }
   // 最新产品分类 详情
   mainManage.getGoodsTypeGetById = (payload) => {
    return ajaxinstance.post(`cms/newestgoodstype/getById`, payload,{
      ...header
    })
  }
  // 最新产品分类 编辑
  mainManage.getGoodsTypeEdit = (payload) => {
    return ajaxinstance.post(`cms/newestgoodstype/edit`, payload,{
      ...header
    })
  }
   // 最新产品分类 新增
   mainManage.getGoodsTypeSave = (payload) => {
    return ajaxinstance.post(`cms/newestgoodstype/save`, payload,{
      ...header
    })
  }
  //最新促销 列表
   mainManage.salespromotionQueryList = (payload) => {
      return ajaxinstance.post(`cms/salespromotion/queryList`, payload,{
        ...header
      })
   }
   //最新促销 详情
   mainManage.salespromotionGetDetonationById = (payload) => {
    return ajaxinstance.post(`cms/salespromotion/getDetonationById`, payload,{
      ...headerForm
    })
   }
    //最新促销 删除
    mainManage.operateSaleSpromotionDelete = (payload) => {
      return ajaxinstance.post(`cms/salespromotion/operateSaleSpromotionDelete`, payload,{
        ...headerForm
      })
    }
    //最新促销 排序
    mainManage.operateSaleSpromotionSortOrder = (payload) => {
      return ajaxinstance.post(`cms/salespromotion/operateSaleSpromotionSortOrder`, payload,{
        ...header
      })
    } 
    //最新促销 上下架
     mainManage.operateSaleSpromotionState = (payload) => {
      return ajaxinstance.post(`cms/salespromotion/operateSaleSpromotionState`, payload,{
        ...headerForm
      })
    }
     //最新促销 新增编辑
     mainManage.operateSaleSaveOrUpdate = (payload) => {
      return ajaxinstance.post(`cms/salespromotion/saveOrUpdate`, payload,{
        ...header
       })
     }
    return {
      mainManage
    }
  }
  export default mainCreate
  
const sendSystemManage = (ajaxinstance) => {
    let closeStoreManage = {}
    const header = {
        headers: {
            'content-Type': 'application/json; charset=UTF-8'
        }
    }
    // 获取站内信列表
    sendSystemManage.getInmailList = (payload) => {
        return ajaxinstance.post(`cms/inmail/getInmailList`, payload, {
            ...header
        })
    }
     // 获取站内信列表
     sendSystemManage.getInmailDetailListById = (payload) => {
        return ajaxinstance.post(`cms/inmail/getInmailDetailListById`, payload, {
            ...header
        })
    }
    //获取站内信详情
    sendSystemManage.getInmailById = (payload) => {
        return ajaxinstance.post(`cms/inmail/getInmailById`, payload, {
            ...header
        })
    }
    //更新站内信
    sendSystemManage.updateInmailById = (payload) => {
        return ajaxinstance.post(`cms/inmail/updateInmailById`, payload, {
            ...header
        })
    }
    //删除站内信
    sendSystemManage.deleteInmailById = (payload) => {
        return ajaxinstance.post(`cms/inmail/deleteInmailById`, payload, {
            ...header
        })
    }
     //发送站内信
     sendSystemManage.addInmailById = (payload) => {
        return ajaxinstance.post(`cms/inmail/addInmailById`, payload, {
            ...header
        })
    }
    return {
        sendSystemManage
    }
}
export default sendSystemManage
const workLog = (ajaxinstance) => {
    let logManage = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
     }
     // 门店列表
     logManage.queryCmsLoglist = (payload) => {
        return ajaxinstance.post(`cms/log/queryCmsLoglist`,payload,{
            ...header
        })
    }
    return {
        logManage
    }
  }
  export default workLog
import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const ActivityList = Loadable({
  loader: () => import('./activity-list'),
  loading: Loading
})
export default (
  <React.Fragment>
    <Route path='/ActivityList' component={ActivityList} />
  </React.Fragment>
)

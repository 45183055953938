import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const RoleList = Loadable({
    loader: () => import('./role-list'),
    loading: Loading
})
const AdminList = Loadable({
    loader: () => import('./admin-list'),
    loading: Loading
})
const ResourceList = Loadable({
    loader: () => import('./resource-list'),
    loading: Loading
})
export default (
    <React.Fragment>
        <Route path='/RoleList' component={RoleList} />
        <Route path='/AdminList' component={AdminList} />
        <Route path='/ResourceList' component={ResourceList} />

    </React.Fragment>
)

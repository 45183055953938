import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

  const NewProductList = Loadable({
    loader: () => import('./new-product-list'),
    loading: Loading
  })
  const ClassifiedManage = Loadable({
    loader: () => import('./classified-manage'),
    loading: Loading
  })
export default (
  <React.Fragment>
    <Route path='/NewProductList' component={NewProductList} />
    <Route path='/ClassifiedManage' component={ClassifiedManage} />
  </React.Fragment>
)

const discoverManageCreate = (ajaxinstance) => {
		const discoverManage = {}
		const header = {
			headers: {
				'content-Type': 'application/json; charset=UTF-8'
			}
		}
		
		//更新发现
		discoverManage.operateDiscoveryUpdate = (payload) => {
			return ajaxinstance.post(`cms/discovery/operateDiscoveryUpdate`,payload,{...header})
		}
		//审核发现
		discoverManage.operateDiscoveryVerify = (payload) => {
			return ajaxinstance.post(`cms/discovery/operateDiscoveryVerify`,payload,{...header})
		}
	
		//查询发现举报列表
		discoverManage.queryDiscoveryAccusationList = (payload) => {
			return ajaxinstance.post(`cms/discoveryAccusation/queryDiscoveryAccusationList`,payload,{...header})
		}
		//删除举报
		discoverManage.deleteDiscoveryAccusationById = (payload) => {
			return ajaxinstance.post(`cms/discoveryAccusation/deleteDiscoveryAccusationById`,payload,{...header})
		}
		//根据id查询当前发现举报详情
		discoverManage.operateDiscoveryAccusationById = (payload) => {
			return ajaxinstance.post(`cms/discoveryAccusation/operateDiscoveryAccusationById`,payload,{...header})
		}
		//屏蔽举报
		discoverManage.shieldDiscoveryAccusationById = (payload) => {
			return ajaxinstance.post(`cms/discoveryAccusation/shieldDiscoveryAccusationById`,payload,{...header})
		}
		//查询发现评论列表
		discoverManage.queryDiscoveryCommentList = (payload) => {
			return ajaxinstance.post(`cms/discoveryComment/queryDiscoveryCommentList`,payload,{...header})
		}
		//根据id查询当前发现评论详情
		discoverManage.operateDiscoveryCommentById = (payload) => {
			return ajaxinstance.post(`cms/discoveryComment/operateDiscoveryCommentById`,payload,{...header})
		}
		//屏蔽评论
		discoverManage.shieldDiscoveryCommentById = (payload) => {
			return ajaxinstance.post(`cms/discoveryComment/shieldDiscoveryCommentById`,payload,{...header})
		}
		
		//查询敏感词列表
		discoverManage.queryDiscoverySensitiveList = (payload) => {
			return ajaxinstance.post(`cms/discoverySensitive/shieldDiscoveryCommentById`,payload,{...header})
		}
		//导出全部敏感词
		discoverManage.operateDiscoverySensitiveExport = (payload) => {
			return ajaxinstance.get(`cms/discoverySensitive/operateDiscoverySensitiveExport`,payload,{...header})
		}
		//批量导入敏感词
		discoverManage.operateDiscoverySensitiveImport = (payload) => {
			return ajaxinstance.post(`cms/discoverySensitive/operateDiscoverySensitiveImport`,payload,{...header})
		}
		//新增敏感词
		discoverManage.operateDiscoverySensitiveSave = (payload) => {
			return ajaxinstance.post(`cms/discoverySensitive/operateDiscoverySensitiveSave`,payload,{...header})
		}
		//删除敏感词
		discoverManage.operateDiscoverySensitiveDelete = (payload) => {
			return ajaxinstance.post(`cms/discoverySensitive/operateDiscoverySensitiveDelete`,payload,{...header})
		}
		//查询特殊显示列表
		discoverManage.queryDiscoverySpecialList = (payload) => {
			return ajaxinstance.post(`cms/discoverySpecial/operateDiscoveryLabelUpdate`,payload,{...header})
		}
		//根据id查询当前特殊显示详情
		discoverManage.operateDiscoverySpecialById = (payload) => {
			return ajaxinstance.post(`cms/discoverySpecial/operateDiscoverySpecialById`,payload,{...header})
		}
		//删除特殊显示
		discoverManage.operateDiscoverySpecialDelete = (payload) => {
			return ajaxinstance.post(`cms/discoverySpecial/operateDiscoverySpecialDelete`,payload,{...header})
		}
		//新增图片信息
		discoverManage.operateDiscoverySpecialImagesSave = (payload) => {
			return ajaxinstance.post(`cms/discoverySpecial/operateDiscoverySpecialImagesSave`,payload,{...header})
		}
		//新增特殊显示
		discoverManage.operateDiscoverySpecialSave = (payload) => {
			return ajaxinstance.post(`cms/discoverySpecial/operateDiscoverySpecialSave`,payload,{...header})
		}
		//编辑特殊显示
		discoverManage.operateDiscoverySpecialUpdate = (payload) => {
			return ajaxinstance.post(`cms/discoverySpecial/operateDiscoverySpecialUpdate`,payload,{...header})
		}
		return {
			discoverManage
		}
}
export default discoverManageCreate

import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const BarCodeList = Loadable({
  loader: () => import('./barCode-list'),
  loading: Loading
})
const CreateBarcodes = Loadable({
    loader: () => import('./create-barcodes'),
    loading: Loading
  })
export default (
  <React.Fragment>
    <Route exact path='/BarCodeList' component={BarCodeList} />
    <Route exact path='/CreateBarcodes' component={CreateBarcodes} />
  </React.Fragment>
)

const storeCreate = (ajaxinstance) => {
    let storeManage = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
     }
     // 门店列表
    storeManage.storeList = (payload) => {
        return ajaxinstance.post(`cms/store/list`,payload,{
            ...header
        })
    }
      // 门店列表
      storeManage.operateAppointmentListByFilter = (payload) => {
        return ajaxinstance.post(`cms/appointment/operateAppointmentListForManagerByFilter
        `,payload,{
            ...header
        })
    }
    // 编辑门店
    storeManage.editStore = (payload) => {
        return ajaxinstance.post(`cms/store/edit`,payload,{
            ...header
        })
    }
    // 设置样板店or非样板店
    storeManage.editTemplet = (payload) => {
        return ajaxinstance.post(`cms/store/editTemplet`,payload,{
            headers: {
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
     // 同步门店
     storeManage.synStoreByCode = (payload) => {
        return ajaxinstance.post(`cms/store/synStoreByCode`,payload,{
            ...header
        })
    }
    //  详情
    storeManage.operateStoreById = (payload) => {
        return ajaxinstance.post(`cms/store/operateStoreById`,payload,{
            ...header
        })
    }
    return {
        storeManage
    }
  }
  export default storeCreate
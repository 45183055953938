// 性别
const sex = [
  { key:1, value:'男' },
  { key:2, value:'女' }
]
//经纬度
const longitude = [
  { key:'', value:'全部' },
  { key:1, value:'无' }
]
//是否有样板店 
const isTemplet = [
  { key:1, value:'样板店' },
  { key:2, value:'非样板店' }
]
//是否有样板店 
const openState = [
  { key:0, value:'开' },
  { key:1, value:'关' }
]
// 帐号状态
const accountStatus = [
  { key:0, value:'正常' },
  { key:1, value:'冻结' }
]
// 数据来源
const dataSources = [
  { key:0, value:'小程序' },
  { key:1, value:'微信H5' },
  { key:2, value:'POS系统' },
  { key:3, value:'APP' }
]
//用户端
const userSide=[
  {key:1,value:'小程序'},
  {key:2,value:'微信端'},
  {key:3,value:'小程序&微信端'}
]
// 积分任务
const ucoinTask = [
  { key:1, value:'分享小程序' },
  { key:2, value:'分享微信端' },
  { key:3, value:'扫码商品' },
  { key:4, value:'浏览U币商城' }
]
//适用会员
const applicableMembers = [
  { key:1, value:'悦薇粉丝' },
  { key:2, value:'银卡会员' },
  { key:3, value:'金卡会员' },
  { key:4, value:'钻石会员' },
]
//上下架  状态
const status = [
  { key:0, value:'下架' },
  { key:1, value:'上架' }
]
//感谢礼状态
const isUp = [
  { key:0, value:'待上架' },
  { key:2, value:'下架' },
  { key:1, value:'上架' }
]
//预约状态
const bookStatus = [
  { key:0, value:'待发货' },
  { key:1, value:'待收货' },
  { key:2, value:'已完成' },
  { key:3, value:'已取消' }
]
//兑换状态
const exchangeStatus = [
  { key:0, value:'兑换成功' },
  { key:1, value:'已取消' },
]
//使用状态
const useStatus = [
  { key:1, value:'待使用' },
  { key:2, value:'已使用' },
  { key:3, value:'已过期' }
]
//兑换类型
const exchangeType = [
  { key:1, value:'商品' },
  { key:2, value:'礼券' },
]
//店铺类型
const storeType = [
  { key:1, value:'路面店' },
  { key:2, value:'商城店' },
]
//评价内容
const evaluationContent=[
  {key:1,value:'环境'},
  {key:2,value:'服务'},
  {key:3,value:'专业'},
  {key:4,value:'整体'}
]
//评论星级
const evaluationRate=[
  {key:1,value:'1星'},
  {key:2,value:'2星'},
  {key:3,value:'3星'},
  {key:4,value:'4星'},
  {key:5,value:'5星'}
]
//数据类型
const dataType=[
  {key:1,value:'省份/地区'},
  {key:1,value:'城市'},
  {key:1,value:'店铺类型'},
  {key:1,value:'柜台类型'},
  {key:1,value:'连锁系统'}
]
//处理状态
const processStatus=[
  {key:1,value:'已处理'},
  {key:1,value:'未处理'}
]
//activity状态
const activityStatus=[
  {key:0,value:'待生效'},
  {key:1,value:'生效中'},
  {key:2,value:'已过期'},
  {key:3,value:'已作废'}
]
//礼券状态
const giftStatus=[
  {key:0,value:'已领取'},
  {key:1,value:'已使用'},
  {key:2,value:'已过期'},
  // {key:3,value:'未发放'}
]
// 绑定门店审核状态
const bindStoreAuditsStatus=[
  {key:0,value:'待审核'},
  {key:1,value:'已通过'},
  {key:2,value:'不通过'}
]
// 反馈状态
const feedbackStatus=[
  {key:0,value:'未处理'},
  {key:1,value:'已处理'},
]
// 订单类型
const orderType=[
  {key:1,value:'寄送'},
  {key:2,value:'到店'},
]
//兑换状态
const exchangeRecordStatus=[
  {key:0,value:'待领取'},
  {key:1,value:'已领取'},
  {key:2,value:'已过期'}
]
//商品状态
const goodsStatus=[
  {key:1,value:'已上架'},
  {key:0,value:'待上架'},
  {key:2,value:'已下架'}
]
//订单状态
const orderStatus = [
  { key:1, value:'待发货' },
  { key:2, value:'待收货' },
  { key:4, value:'已完成' },
  { key:3, value:'已取消' }
]
//live订单状态
const liveOrderStatus = [
  { key:0, value:'待支付' },
  { key:1, value:'待使用' },
  { key:3, value:'已取消' },
  { key:4, value:'已完成' },
  { key:5, value:'已过期' }
]
//奖品类型
const prizeType = [
  { key:1, value:'商品' },
  { key:2, value:'礼券' },
  { key:3, value:'积分' },
]

//活动类型
const activityType = [
	{ key: 1, value: '普通活动' },
	{ key: 2, value: '调查问券' },
	{ key: 3, value: '投票上墙' },
]
//activityList状态
const activityListStatus = [
	{ key: 0, value: '已结束' },
	{ key: 1, value: '进行中' },
	{ key: 2, value: '未开启' },
]
// 活动状态
const activityShows = [
	{ key: 1, value: '上架' },
	{ key: 0, value: '下架' }
]
const activityTypeOptions = [
	{ key: 0, value: '审核' },
	{ key: 1, value: '秒杀' }
]
const bindActivityAuditsStatus = [
	{ key: 0, value: '待审核' },
	{ key: 1, value: '已通过' },
	{ key: 2, value: '已驳回' }
]
// 绑定投票审核状态
const bindVoteStatus = [
	{ key: 1, value: '上架' },
	{ key: 0, value: '下架' }
]
// 预约到店状态
const bookStoreStatus = [
	{ key: 0, value: '已预约' },
  { key: 1, value: '已到店' },
  { key: 3, value: '已取消' },
  { key: 4, value: '已过期' },
]
const codeState = [
	{ key: 0, value: '正常(创建)' },
  { key: 1, value: '作废' },
  { key: 2, value: '失效' },
  { key: 3, value: '已扫码' },
]
export default {
  sex,
  accountStatus,
  dataSources,
  ucoinTask,
  applicableMembers,
  status,
  bookStatus,
  exchangeStatus,
  useStatus,
  exchangeType,
  storeType,
  evaluationContent,
  evaluationRate,
  dataType,
  processStatus,
  activityStatus,
  giftStatus,
  bindStoreAuditsStatus,
  feedbackStatus,
  orderType,
  userSide,
  exchangeRecordStatus,
  goodsStatus,
  orderStatus,
  liveOrderStatus,
  isUp,
	prizeType,
	activityType,
	activityListStatus,
	activityShows,
	activityTypeOptions,
	bindActivityAuditsStatus,
	bindVoteStatus,
  longitude,
  isTemplet,
  bookStoreStatus,
  codeState,
  openState
}

import config from '../utils/config'
const mallManageCreate = (ajaxinstance) => {
    const mallManage = {}
    const header = {
      headers: {
          'content-Type':'application/json; charset=UTF-8'
      }
   }
     //banner管理 列表
    mallManage.queryList = (payload) => {
      return ajaxinstance.post(`cms/umallBanner/queryList`, payload,{
        ...header
      })
    }
     //banner管理 排序
    mallManage.sortBanner = (payload) => {
        return ajaxinstance.post(`cms/umallBanner/sortBanner`, payload,{
          ...header
        })
    }
    //banner管理 上下架
    mallManage.shelvesBanner = (payload) => {
        return ajaxinstance.post(`cms/umallBanner/shelvesBanner`, payload,{
          ...header
        })
    }
     //banner管理 新增
    mallManage.umallBannerSave = (payload) => {
        return ajaxinstance.post(`cms/umallBanner/save`, payload,{
          ...header
      })
    }
     //banner管理 编辑
    mallManage.editBanner = (payload) => {
        return ajaxinstance.post(`cms/umallBanner/editBanner`, payload,{
          ...header
      })
    }
     //banner管理 删除
     mallManage.deleteBanner = (payload) => {
        return ajaxinstance.post(`cms/umallBanner//deleteBanner`, payload,{
          ...header
      })
    }
    //商城分类  分类列表
    mallManage.goodstypeList = (payload) => {
        return ajaxinstance.post(`cms/goodstype/queryList`, payload,{
        ...header
        })
    }
    //商城分类 新增
    mallManage.goodstypeSave = (payload) => {
        return ajaxinstance.post(`cms/goodstype/save`, payload,{
        ...header
        })
    }
     //商城分类 编辑、详情
     mallManage.goodstypeEdit = (payload) => {
        return ajaxinstance.post(`cms/goodstype/edit`, payload,{
        ...header
        })
    }
    //商城分类 删除
    mallManage.goodstypeDelete = (payload) => {
        return ajaxinstance.post(`cms/goodstype/delete`, payload,{
        ...header
        })
    }
    //商城商品列表 列表
    mallManage.queryGoodsList = (payload) => {
        return ajaxinstance.post(`cms/goods/queryGoodsList`, payload,{
        ...header
        })
    }
    //商城商品列表 新增
    mallManage.operateGoodsSave = (payload) => {
        return ajaxinstance.post(`cms/goods/operateGoodsSave`, payload,{
            // headers:{
            //     'content-Type':'application/x-www-form-urlencoded'
            // }
            ...header
        })
    }
    //商城商品列表 编辑
     mallManage.operateGoodsUpdate = (payload) => {
        return ajaxinstance.post(`cms/goods/operateGoodsUpdate`, payload,{
            ...header
        })
    }
    //商城商品列表 详情
    mallManage.operateGoodsById = (payload) => {
        return ajaxinstance.post(`cms/goods/operateGoodsById`, payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
    //商城商品列表 编辑库存
    mallManage.operateGoodsUpdateNumber = (payload) => {
        return ajaxinstance.post(`cms/goods/operateGoodsUpdateNumber`, payload,{
            ...header
        })
    }
    //商城商品列表 删除
    mallManage.operateGoodsDelete = (payload) => {
        return ajaxinstance.post(`cms/goods/operateGoodsDelete`, payload,{
            ...header
        })
    }
    //商城商品列表 上下架
    mallManage.operateGoodsUpDown = (payload) => {
        return ajaxinstance.post(`cms/goods/operateGoodsUpDown`, payload,{
            ...header
        })
    }
    //商城商品 置顶
    mallManage.operateGoodsUpdateTop = (payload) => {
        return ajaxinstance.post(`cms/goods/operateGoodsUpdateTop`, payload,{
            ...header
        })
    }
    //商城礼券 置顶
    mallManage.operateUmallCardUpdateTop = (payload) => {
        return ajaxinstance.post(`cms/umallCard/operateUmallCardUpdateTop`, payload,{
            ...header
        })
    }
    // 商城礼券列表  列表
    mallManage.queryUmallCardList = (payload) => {
        return ajaxinstance.post(`cms/umallCard/queryUmallCardList`, payload,{
            ...header
        })
    }
    // 商城礼券列表  详情
     mallManage.operateUmallCardById = (payload) => {
        return ajaxinstance.post(`cms/umallCard/operateUmallCardById`, payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
     // 商城礼券列表  新增
     mallManage.operateUmallCardSave = (payload) => {
        return ajaxinstance.post(`cms/umallCard/operateUmallCardSave`, payload,{
            // headers:{
            //     'content-Type':'application/x-www-form-urlencoded'
            // }
            ...header
        })
    }
     // 商城礼券列表  新增
     mallManage.operateUmallCardUpdate = (payload) => {
        return ajaxinstance.post(`cms/umallCard/operateUmallCardUpdate`, payload,{
            ...header
        })
    }
    // 商城礼券列表  上下架
    mallManage.operateUmallCardUpDown = (payload) => {
        return ajaxinstance.post(`cms/umallCard/operateUmallCardUpDown`, payload,{
            ...header
        })
    }
    // 商城礼券列表  编辑库存
    mallManage.operateUmallCardUpdateNumber = (payload) => {
        return ajaxinstance.post(`cms/umallCard/operateUmallCardUpdateNumber`, payload,{
            ...header
        })
    }
    // 商城礼券列表 删除
    mallManage.operateUmallCardDelete = (payload) => {
        return ajaxinstance.post(`cms/umallCard/operateUmallCardDelete`, payload,{
            ...header
        })
    }
    // 置顶管理  列表
    mallManage.queryUmallTopCategoryList = (payload) => {
        return ajaxinstance.post(`cms/umallTopCategory/queryUmallTopCategoryList`, payload,{
            ...header
        })
    }
    // 置顶管理  取消置顶
    mallManage.cancelUmallTopCategoryById = (payload) => {
        return ajaxinstance.post(`cms/umallTopCategory/cancelUmallTopCategoryById`, payload,{
            ...header
        })
    }
     // 置顶管理  批量取消置顶
     mallManage.cancelUmallTopCategoryByIdList = (payload) => {
        return ajaxinstance.post(`cms/umallTopCategory/cancelUmallTopCategoryByIdList`, payload,{
            ...header
        })
    }
    // 编辑置顶排序
		mallManage.sortTop = (payload) => {
			return ajaxinstance.post(`cms/umallTopCategory/topSort`, payload,{
				...header
			})
		}
    return {
        mallManage
    }
  }
  export default mallManageCreate

const myDates = new Date().toLocaleDateString()
const regS = new RegExp('/', 'g')
let myLocalDate = myDates.replace(regS, '-')
const myDate = myLocalDate.substring(0, myLocalDate.lastIndexOf('-'))
function getPreMonth () { // 获取上月方法
  let arr = myDate.split('-')
  let year = arr[0] // 获取当前日期的年份
  let month = arr[1] // 获取当前日期的月份
  let year2 = year
  let month2 = parseInt(month) - 1
  if (month2 === 0) {
    year2 = parseInt(year2) - 1
    month2 = 12
  }
  if (month2 < 10) {
    month2 = '0' + month2
  }
  let t2 = year2 + '-' + month2
  return t2
}
function getAfterDate(month){//获取month个月之后
  let d = new Date();
  d.setMonth(d.getMonth() + month);
  let yy1 = d.getFullYear();
  let mm1 = d.getMonth()+1;//因为getMonth（）返回值是 0（一月） 到 11（十二月） 之间的一个整数。所以要给其加1
  let dd1 = d.getDate();
  if (mm1 < 10 ) {
      mm1 = '0' + mm1;
  }
  if (dd1 < 10) {
    dd1 = '0' + dd1;
  }
  let t = yy1 + '-' + mm1 + '-' + dd1
  return t
}

export default {
  getAfterDate,
  getPreMonth,
  myDate,
  myLocalDate
}

const weChatAppletManageCreate = (ajaxinstance) => {
    const weChatAppletManage = {}
    const header = {
      headers: {
          'content-Type':'application/json; charset=UTF-8'
      }
   }
     //banner管理 列表
    weChatAppletManage.queryList = (payload) => {
        return ajaxinstance.post(`cms/banner/queryList`,  payload ,{
          ...header
        })
    }
     //banner管理 排序
     weChatAppletManage.sortBanner = (payload) => {
        return ajaxinstance.post(`cms/banner/sortBanner`, payload,{
          ...header
        })
    }
    //banner管理 上下架
    weChatAppletManage.shelvesBanner = (payload) => {
        return ajaxinstance.post(`cms/banner/shelvesBanner`, payload,{
          ...header
        })
    }
     //banner管理 新增
     weChatAppletManage.bannerSave = (payload) => {
        return ajaxinstance.post(`cms/banner/save`, payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
      })
    }
     //banner管理 编辑
     weChatAppletManage.editBanner = (payload) => {
        return ajaxinstance.post(`cms/banner/editBanner`, payload,{
          ...header
      })
    }
    // banner管理 删除 cms/banner/deleteBanner
    weChatAppletManage.deleteBanner = (payload) => {
        return ajaxinstance.post(`cms/banner/deleteBanner`, payload,{
          ...header
      })
    }
    return {
        weChatAppletManage
    }
  }
  export default weChatAppletManageCreate
  
import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const LogList = Loadable({
    loader: () => import('./log-list'),
    loading: Loading
})
export default (
    <React.Fragment>
        <Route path='/WorkLog' component={LogList} />
    </React.Fragment>
)

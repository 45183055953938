import React from 'react'

const Loading = ({ isLoading, error }) => {
  if (isLoading) {
    return <div />
  } else if (error) {
    return <div style={{margin:'30px'}}>网络错误，页面加载失败！！</div>
  } else {
    return null
  }
}

export default Loading

export default function getParams (url) { //将字符串转为对象
  let theRequest = {}
  let strs = ''
  if (url.indexOf('?') !== -1) {
    let str = url.substr(1)
    strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

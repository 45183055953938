const memberCreate = (ajaxinstance) => {
    const memberManage = {}
    const header = {
      headers: {
          'content-Type':'application/json; charset=UTF-8'
      }
    }
     // 列表
    memberManage.operateFindMemberList = (payload) => {
      return ajaxinstance.post(`cms/member/operateFindMemberListByFilter`, payload,{
        ...header
      })
    }
     // app会员列表
     memberManage.operateFindMemberAppList = (payload) => {
      return ajaxinstance.post(`cms/member/operateFindMemberListByFilterForThree`, payload,{
        ...header
      })
    }
    // 详情
    memberManage.operateFindMemberById = (payload) => {
      return ajaxinstance.post(`cms/member/operateFindMemberById`, payload,{
        ...header
      })
    }
    // 冻结
    memberManage.operateFrozenMemberById = (payload) => {
        return ajaxinstance.post(`cms/member/operateFrozenMemberById`, payload,{
          ...header
        })
      }
    //u币列表
     memberManage.operateUcoinListByFilter = (payload) => {
        return ajaxinstance.post(`cms/ucoin/operateUcoinListByFilter`, payload,{
         ...header
        })
    }
    //调节u币
    memberManage.operateUcoinSave = (payload) => {
        return ajaxinstance.post(`cms/ucoin/operateUcoinSave`, payload,{
          ...header
        })
    }
    //消费履历
    memberManage.operateConsumptionListByFilter = (payload) => {
      return ajaxinstance.post(`cms/consumption/operateConsumptionListByFilter`, payload,{
        ...header
      })
    }
    //更新消费履历
    memberManage.operateConsumptionSync = (payload) => {
      return ajaxinstance.get(`cms/member/operateConsumptionSync`, payload,{
        ...header
      })
    }
     //发送消息
     memberManage.sendSMSMemberListByFilter = (payload) => {
      return ajaxinstance.post(`cms/member/sendSMSMemberListByFilter`, payload,{
        ...header
      })
    }
      //查询商品
      memberManage.getProductWithActivityByCode= (payload) => {
        return ajaxinstance.post(`cms/product/getProductWithActivityByCode`, payload,{
          ...header
        })
      }

      //查询个人积分详情
      memberManage.operateSweepCode=(payload)=>{
        return ajaxinstance.post(`cms/ucoin/operateSweepCode`,payload,{
          ...header
        })
      }

    return {
        memberManage
    }
  }
  export default memberCreate
  
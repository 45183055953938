import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const WechatBannerManage = Loadable({
  loader: () => import('./weChat-banner-manage'),
  loading: Loading
})

export default (
  <React.Fragment>
    <Route path='/WechatBannerManage' component={WechatBannerManage} />
  </React.Fragment>
)

// 对象转换为URL查询参数
function toQueryPair (key, value) {
  if (typeof value === 'undefined') {
    return key
  }
  return key + '=' + encodeURIComponent(value === null ? '' : String(value))
}
export default function toQueryString (obj) {
  let ret = []
  for (let key in obj) {
    key = encodeURIComponent(key)
    var values = obj[key]
    if (values && values.constructor === Array) { // 数组
      var queryValues = []
      for (let i = 0, len = values.length, value; i < len; i++) { // 如果有数组
        value = values[i]
        queryValues.push(toQueryPair(key, value))
      }
      ret = ret.concat(queryValues)
    } else { // 字符串
      ret.push(toQueryPair(key, values))
    }
  }
  return ret.join('&')
}

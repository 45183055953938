const orderManageCreate = (ajaxinstance) => {
    let orderManage = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
    }
     // 订单列表
    // orderManage.queryOrderList = (payload) => {
    //     return ajaxinstance.post(`cms/order/queryOrderList`,payload,{
    //         ...header
    //     })
    // }
    // queryOrderListForCrm
    orderManage.queryOrderList = (payload) => {
        return ajaxinstance.post(`cms/order/queryOrderListForCrm`,payload,{
            ...header
        })
    }
    // orderManage.queryOrderList = (payload) => {
    //     return ajaxinstance.post(`cms/order/operateOrderByIdForCrm`,payload,{
    //         ...header
    //     })
    // }
    // 兑换取消记录
    // orderManage.operateOrderById = (payload) => {
    //     return ajaxinstance.post(`cms/order/operateOrderById`,payload,{
    //        ...header
    //     })
    // }
    //订单取消
    orderManage.operateOrderCancel = (payload) => {
        return ajaxinstance.post(`cms/order/operateOrderCancel`,payload,{
            ...header
        })
    }
    //订单备注
    orderManage.operateOrderCancel = (payload) => {
        return ajaxinstance.post(`cms/order/operateOrderCancel`,payload,{
            ...header
        })
    }
    //订单发货
    orderManage.operateOrderDeliver = (payload) => {
        return ajaxinstance.post(`cms/order/operateOrderDeliver`,payload,{
            ...header
        })
    }
    //订单编辑
    orderManage.operateOrderUpdate = (payload) => {
        return ajaxinstance.post(`cms/order/operateOrderUpdate`,payload,{
            ...header
        })
    }
    //订单打印  单独打印
    orderManage.operateOrderPrint = (payload) => {
        return ajaxinstance.post(`cms/order/operateOrderPrint`,payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
    //门店订单查询  批量发货
    orderManage.queryStoreOrderList = (payload) => {
        return ajaxinstance.post(`cms/order/queryStoreOrderList`,payload,{
            ...header
        })
    }
     //订单批量发货
     orderManage.operateOrderListDeliver = (payload) => {
        return ajaxinstance.post(`cms/order/operateOrderListDeliver`,payload,{
            ...header
        })
    }
      // 直播订单列表
      orderManage.queryLiveOrderList = (payload) => {
        return ajaxinstance.post(`cms/ecOrder/queryOrderList`,payload,{
            ...header
        })
    }
     // 查询直播订单详情
     orderManage.operateLiveOrderById = (payload) => {
        return ajaxinstance.post(`cms/ecOrder/operateOrderById`,payload,{
           ...header
        })
    }
    orderManage.operateOrderById = (payload) => {
        return ajaxinstance.post(`cms/order/operateOrderByIdForCrm`,payload,{
           ...header
        })
    }
    // 修改门店
    orderManage.operateOrderUpdateStore = (payload) => {
        return ajaxinstance.post(`cms/order/operateOrderUpdateStore`,payload,{
           ...header
        })
    }
    return {
        orderManage
    }
  }
  export default orderManageCreate
  
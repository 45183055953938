const closeStoreCreate = (ajaxinstance) => {
    let closeStoreManage = {}
    const header = {
        headers: {
            'content-Type': 'application/json; charset=UTF-8'
        }
    }
    // 门店列表
    closeStoreManage.closeStoreList = (payload) => {
        return ajaxinstance.post(`cms/store/closelist`, payload, {
            ...header
        })
    }
    //门店绑定会员
    closeStoreManage.closeStoneUsers = (payload) => {
        return ajaxinstance.post(`cms/store/closeStoneUsers`, payload, {
            ...header
        })
    }
    //发送闭店消息
    closeStoreManage.sendCloseMsgtoUsers = (payload) => {
        return ajaxinstance.post(`cms/store/sendCloseMsgtoUsers`, payload, {
            ...header
        })
    }
    //发送闭店消息(全部)
    closeStoreManage.sendCloseMsgtoAll = (payload) => {
        return ajaxinstance.post(`cms/store/sendCloseMsgtoAll`, payload, {
            ...header
        })
    }
    return {
        closeStoreManage
    }
}
export default closeStoreCreate
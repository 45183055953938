
// 接口host
const host = 'https://t.revital-cosmetics.cn/revitalcrm/' 
const img = 'https://t.revital-cosmetics.cn/'
// const host = 'http://192.168.90.95:9074/'
// const img = 'https://t.revital-cosmetics.cn/'
// 正式环境
// const host = 'https://m.revital-cosmetics.cn/revitalcrm/'
// const img = 'https://m.revital-cosmetics.cn/'
export default {
  host,
  img
}

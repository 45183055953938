import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'
const GiftVoucherList = Loadable({
  loader: () => import('./gift-voucher-list'),
  loading: Loading
})
const GiftVoucherClassify = Loadable({
  loader: () => import('./gift-voucher-classify'),
  loading: Loading
})
export default (
  <React.Fragment>
    <Route exact path='/GiftVoucherList' component={GiftVoucherList} />
    <Route exact path='/GiftVoucherClassify' component={GiftVoucherClassify} />
  </React.Fragment>
)

import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const ReservationList = Loadable({
    loader: () => import('./reservation-list'),
    loading: Loading
})
export default (
    <React.Fragment>
        <Route path='/ReservationList' component={ReservationList} />
    </React.Fragment>
)

import axios from 'axios'
import { message } from 'antd'
import config from '../utils/config'
import { loading } from '../../store/mainReducer'

// 接口模块
import customer from './customer'// 公共接口
import productInfoSync from './product-info-sync' //产品信息同步
import barCodeManage from './barCode-manage' //条码管理
import memberManage from './member-manage'  //会员管理
import storeManage from './store-manage'  //门店管理
import feedbackManage from './feedback-manage' //反馈管理
import bindStoreCreate from './bind-store-audits' //绑定门店审核
import enjoyMeetingManage from './enjoy-meeting-manage'//悠享会
import giftRedemptionManage from './gift-redemption-manage' //兑换管理
import mallManage from './mall-manage'  //u币商城管理
import goodsManege from './goods-manege' //商品管理
import mainManage from './main-manage' //首页管理
import weChatAppletManage from './weChat-applet-manage'//小程序管理
import giftVoucherManage from './gift-voucher-manage'//礼券管理
import exchangeManage from './exchange-manage' //兑换管理
import orderManage from './order-manage' //订单管理
import discoveryManage from './discovery-manage'//发现管理
import enjoyManage from "./enjoy-manage"
import activityManage from './activity-list'//活动管理
import discoverManage from './discover-manage' //发现管理
import closeStoreManage from './close-store-manage' //闭店消息
import workLog from './work-log' //日志
import sendSystemManage from './send-system-manage' //日志
import liveGoodsList from './live-goods-list' //日志
// 实例化 ajax请求对象
axios.defaults.withCredentials = true
const ajaxinstance = axios.create({
  baseURL: config.host,
  timeout: 60000,
  withCredentials: true
})

// 请求拦截器
ajaxinstance
  .interceptors
  .request
  .use((config) => {
    const { data, params } = config
    if ((data && data.noLoading) || (params && params.noLoading)) {
      // 删除loading参数 取消全局loading
      data && data.noLoading && delete data.noLoading
      params && params.noLoading && delete params.noLoading
    } else {
      loading.show()
    }
    return config
  }, (error) => {
    Promise.reject(error)
  })
// 响应拦截器
ajaxinstance
  .interceptors
  .response
  .use((response) => {
    console.log(response)
    loading.hide()
    // // 接口错误提示
    if (response.data && response.data.errorCode == '-1' && response.data.errorMsg == '尚未登录，请登录!') {
      message.error('请先登录')
      let url = location.href.split('#')[0]
      window.location.href = `${url}#/`
      return
    }
    if (response.data.errorCode && response.data.errorCode !== 0) {
      message.error(response.data.errorMsg)
    }
    return response.data
  }, (error) => {
    console.log(error.code, error.config, error.message, error.response.status)
    if (error.response.status == '401') {
      let url = location.href.split('#')[0]
      window.location.href = `${url}#/`
    } else {
      loading.hide()
      message.error('请求失败')
      return Promise.reject(error)
    }

  })

/**
 * [API api接口封装]
 * @type {Object}
 */
const api = {
  ...customer(ajaxinstance),
  ...productInfoSync(ajaxinstance),
  ...barCodeManage(ajaxinstance),
  ...memberManage(ajaxinstance),
  ...storeManage(ajaxinstance),
  ...feedbackManage(ajaxinstance),
  ...bindStoreCreate(ajaxinstance),
  ...enjoyMeetingManage(ajaxinstance),
  ...giftRedemptionManage(ajaxinstance),
  ...mallManage(ajaxinstance),
  ...goodsManege(ajaxinstance),
  ...mainManage(ajaxinstance),
  ...weChatAppletManage(ajaxinstance),
  ...giftVoucherManage(ajaxinstance),
  ...exchangeManage(ajaxinstance),
  ...orderManage(ajaxinstance),
  ...discoveryManage(ajaxinstance),
  ...activityManage(ajaxinstance),
  ...discoverManage(ajaxinstance),
  ...enjoyManage(ajaxinstance),
  ...closeStoreManage(ajaxinstance),
  ...workLog(ajaxinstance),
  ...sendSystemManage(ajaxinstance),
  ...liveGoodsList(ajaxinstance)
}

export default api

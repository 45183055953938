import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const OpinionFeedback = Loadable({
  loader: () => import('./opinion-feedback'),
  loading: Loading
})
const NoStoreFeedback = Loadable({
  loader: () => import('./no-store-feedback'),
  loading: Loading
})
export default (
  <React.Fragment>
    <Route exact path='/OpinionFeedback' component={OpinionFeedback} />
    <Route exact path='/NoStoreFeedback' component={NoStoreFeedback} />
  </React.Fragment>
)

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { api } from '@/common/app'
import { Modal, Spin, Layout, Menu, Icon } from 'antd'
const { Header, Content, Sider } = Layout
const SubMenu = Menu.SubMenu
const confirm = Modal.confirm
class Router extends React.Component {
  constructor() {
    super()
    this.state = {
      menuList: [],
      openKeys: [],
      current: '',
      collapsed: false
    }
  }
  componentDidMount() {
    this.getMenu()
  }
  // 获取侧边栏
  getMenu = () => {
    // const userId=window.localStorage.getItem('userId')
    const userId = 4;
    api
      .customer
      .showMenu(userId)
      .then(res => {
        if (res.data && res.errorCode == 0) {
          this.setState({ menuList: res.data.currentMenuData.items },
            () => {
              this.defaultSelect()
            })
        } else {

        }
      })
  }
  onCollapse = (collapsed) => {
    this.setState({ collapsed })
  }
  select = (menuItem) => {
    const { menuList } = this.state
    const { history } = this.props
    const that = this
    menuList.map((item, index) => {
      item.list.map((item, index) => {
        if (menuItem.key == item.id) {
          history.push(item.href)
          that.setState({
            current: menuItem.key
          })
        }
      })
    })
  }
  defaultSelect = () => {
    const { location } = this.props
    const self = this
    if (location.pathname !== '/') {
      this.state.menuList.map((item, index) => {
        item.list.map((item, index) => {
          const href = '/' + item.href
          if (location.pathname === href) {
            self.setState({ current: item.id + '', openKeys: [item.pid + ''] })
          }
        })
      })
    }
  }
  onOpenChange = (info) => {
    this.setState({
      openKeys: info.length === 1 ? info : info.slice(1)
    })
  }
  logout = () => {
    const { history } = this.props
    confirm({
      title: '您确定退出系统吗？',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        api
          .customer
          .logout()
          .then(res => {
            // if (res && res.errorCode == 0) {
            //   window.localStorage.removeItem('userId')
            //   window.localStorage.removeItem('username')
            //   history.replace('/')
            // }
            console.log(res)
            let url = location.href.split('#')[0]
            window.location.href = `${url}#/`
            window.localStorage.removeItem('userId')
            window.localStorage.removeItem('username')
            history.replace('/')
          })
      },
      onCancel() {
      }
    })
  }
  render() {
    const { openKeys, collapsed, menuList } = this.state
    return (
      <Layout style={{ height: '100%', overflow: 'hidden' }}>
        <Header className="header"
          style={{
            color: 'rgba(255,255,255,0.85)', heigh: '100px',
            width: '100%', position: 'fixed', top: 0,
            left: 0, zIndex: '900', display: 'flex'
          }}
        >
          <div style={{ fontSize: '20px', flex: '1' }}>悦薇后端管理平台</div>
          <div style={{ fontSize: '18px', flex: '1', textAlign: 'right' }}>
            <span style={{ fontSize: '16px', marginRight: '30px' }}>
              欢迎登录：{window.localStorage.getItem('username')}
            </span>
            <Icon type="logout" onClick={this.logout} />
          </div>
        </Header>
        <Sider
          // collapsible
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          className="leftMenu"
          style={{ marginTop: 60, overflow: 'auto', height: '92vh', position: 'fixed', left: 0 }}>
          {menuList && !menuList.length ? '' : <Menu
            theme='dark'
            mode='inline'
            onSelect={this.select}
            openKeys={openKeys}
            onOpenChange={this.onOpenChange}
            selectedKeys={[this.state.current]}
          >
            {
              menuList && menuList.map((item, index) => {
                return <SubMenu key={item.id}
                  title={<span><Icon type={item.icon} /><span>{item.text}</span></span>}>
                  {
                    item
                      .list && item
                        .list
                        .map((item) => {
                          return <Menu.Item key={item.id} href={item.href}>{item.text}</Menu.Item>
                        })
                  }
                </SubMenu>
              })
            }
          </Menu>}
        </Sider>
        <Layout className="rightPanel" style={{
          background: '#fff', overflow: 'auto',
          display: 'flex', marginLeft: collapsed ? 80 : 200,
          width: '100%', marginTop: '59px', height: '90vh'

        }}>
          <Content>
            <Spin spinning={this.props.main.loading} style={{ zIndex: 9999 }}>
              <div style={{ flex: 1 }}>
                {this.props.children}
              </div>
            </Spin>
          </Content>
        </Layout>
      </Layout>
    )
  }
}
Router.propTypes = {
  children: PropTypes.object,
  history: PropTypes.object,
  main: PropTypes.object
}
export default withRouter(connect((state) => ({ main: state.main }))(Router))
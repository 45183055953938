const bindStoreCreate = (ajaxinstance) => {
    let bindStoreAudits = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
    }
     // 反馈列表
     bindStoreAudits.operateCheckListByFilter = (payload) => {
        return ajaxinstance.post(`cms/store/operateCheckListByFilter`,payload,{
            ...header
        })
    }
    //详情
    bindStoreAudits.operateCheckById = (payload) => {
        return ajaxinstance.post(`cms/store/operateCheckById`,payload,{
            ...header
        })
    }
    //处理
    bindStoreAudits.operateCheckByFilter = (payload) => {
        return ajaxinstance.post(`cms/store/operateCheckByFilter`,payload,{
            ...header
        })
    }
    return {
        bindStoreAudits
    }
  }
  export default bindStoreCreate
  
import config from '../utils/config'
const goodsManegeCreate = (ajaxinstance) => {
    const goodsManege = {}
    const header = {
      headers: {
          'content-Type':'application/json; charset=UTF-8'
      }
   }
     //商品列表 列表
    goodsManege.queryCommodityList = (payload) => {
      return ajaxinstance.post(`cms/commodity/queryCommodityList`, payload, {
        ...header
      })
    }
     //商品列表 删除
    goodsManege.operateCommodityDelete = (payload) => {
        return ajaxinstance.post(`cms/commodity/operateCommodityDelete`, payload,{
          ...header
        })
    }
    //商品列表 详情
    goodsManege.operateCommodityById = (payload) => {
        return ajaxinstance.post(`cms/commodity/operateCommodityById`, payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
    //商品列表 新增
    goodsManege.operateCommoditySave = (payload) => {
        return ajaxinstance.post(`cms/commodity/operateCommoditySave`, payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
    //商品列表 修改
    goodsManege.operateCommodityUpdate = (payload) => {
        return ajaxinstance.post(`cms/commodity/operateCommodityUpdate`, payload,{
          ...header
        })
    }
    //商品列表 获取商品信息详情
    goodsManege.queryCommodityByGoodNo = (payload) => {
        return ajaxinstance.post(`cms/commodity/queryCommodityByGoodNo`, payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
    return {
        goodsManege
    }
  }
  export default goodsManegeCreate
  
import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const MemberList = Loadable({
  loader: () => import('./member-list'),
  loading: Loading
})
const MemberListApp = Loadable({
  loader: () => import('./member-list-app'),
  loading: Loading
})
const UcoinMessage= Loadable({
  loader: () => import('./ucoin-message'),
  loading: Loading
})
const ConsumerResume= Loadable({
  loader: () => import('./consumer-resume'),
  loading: Loading
})
export default (
  <React.Fragment>
    <Route path='/MemberList' component={MemberList} />
    <Route path='/MemberListApp' component={MemberListApp} />
    <Route path='/UcoinMessage' component={UcoinMessage} />
    <Route path='/ConsumerResume' component={ConsumerResume} />
  </React.Fragment>
)

import React from 'react'
import PropTypes from 'prop-types'
import { api,config } from '@/common/app'
import { Row, Form, Icon, Input, Button } from 'antd';
import qs from 'qs'
import md5 from 'md5'
import './login.less'
const FormItem = Form.Item
class LoginPage extends React.Component {
  constructor(prop) {
    super(prop)
    this.state = {
      code: '',
      session: '',
    }
  }
  componentWillMount() {
    const url = window.location.href
    console.log(url)
    if (url.indexOf('?') != -1) {
      const codeUrl = url.split('#');
      const urlState = codeUrl[0].split('?');
      const para = urlState[1].split('&')
      const code = para[0].split('=')[1]
      const session = para[1].split('=')[1]
      console.log(code, session)
      let values = {
        code: code
      }
      const params = qs.stringify(values)
      api
        .customer
        .login(params)
        .then(res => {
          if (res && res.errorCode == 0) {
            window.localStorage.setItem('username', res.data.nickName)
            window.localStorage.setItem('userId', res.data.userId)
            window.localStorage.setItem('dataType', res.data.role.dataType)
            window.location.href = `${url.split('?')[0]}#/home`
            this.props.history.replace('/home')
          }
        })
      this.setState({
        code: code,
        session: session
      })
    }
  }
  handleSubmit = () => {
    const { code } = this.state
    if (code) {
      let values = {
        code: code
      }
      const params = qs.stringify(values)
      console.log(this.state)
      api
        .customer
        .login(params)
        .then(res => {
          if (res && res.errorCode == 0) {
            window.localStorage.setItem('username', res.data.nickName)
            window.localStorage.setItem('userId', res.data.userId)
            window.localStorage.setItem('dataType', res.data.role.dataType)
            const url = window.location.href
            window.location.href = `${url.split('?')[0]}#/home`
            this.props.history.replace('/home')
          }
        })
    }else{
      if(config.host == 'https://t.revital-cosmetics.cn/revitalcrm/'){
        window.location.href = 'https://login.chinacloudapi.cn/ea51c20f-2683-4586-845c-76fab6184916/oauth2/v2.0/authorize?response_type=code&client_id=87c72197-2a9e-44c0-9860-e6c0a428a11a&redirect_uri=https://t.revital-cosmetics.cn/pg&scope=User.Read'
     }else{
        window.location.href = 'https://login.chinacloudapi.cn/ea51c20f-2683-4586-845c-76fab6184916/oauth2/v2.0/authorize?response_type=code&client_id=33604ab9-22f1-4720-99f5-0b61b04fd712&redirect_uri=https://m.revital-cosmetics.cn/redirect&scope=User.Read'
     }
    }

  }
  onkeydown = (e) => {
    let keynum = e.keyCode
    if (keynum === 13) {
      this.handleSubmit()
    }
  }
  
  render () {
    const { getFieldDecorator } = this.props.form
    return (<div className='login-page'>
      <Form className="login-form">
      <Row className='login-form-row'>悦薇后端管理平台</Row>
        {/* <FormItem>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: '请输入用户名' }],
          })(
            <Input maxLength={20} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: '请输入密码' }],
          })(
            <Input maxLength={16} autoComplete={'on'}
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} 
              type="password" placeholder="密码"
              onKeyDown={this.onkeydown}
             />
          )}
        </FormItem> */}
        <FormItem>
          <Button type="primary" onClick={this.handleSubmit} className="login-form-button">
            登录
          </Button>
        </FormItem>
      </Form>
    </div>
    )
  }
}

LoginPage.propTypes = {
  form: PropTypes.object.isRequired,
  history: PropTypes.object
}

export default Form.create()(LoginPage)

const giftRedemptionCreate = (ajaxinstance) => {
    const giftRedemptionManage = {}
    const header = {
      headers: {
          'content-Type':'application/json; charset=UTF-8'
      }
   }
     //礼品管理 列表
    giftRedemptionManage.operateUcoinSampleListByFilter = (payload) => {
      return ajaxinstance.post(`cms/ucoinSample/operateUcoinSampleListByFilter`, payload,{
        ...header
      })
    }
    //礼品管理 新增
    giftRedemptionManage.operateUcoinSampleSave = (payload) => {
        return ajaxinstance.post(`cms/ucoinSample/operateUcoinSampleSave`, payload,{
          ...header
        })
    }
    //礼品管理 详情 
    giftRedemptionManage.operateUcoinSampleById = (payload) => {
        return ajaxinstance.post(`cms/ucoinSample/operateUcoinSampleById`, payload,{
          ...header
        })
    }
     //礼品管理 编辑 
    giftRedemptionManage.operateUcoinSampleUpdate = (payload) => {
        return ajaxinstance.post(`cms/ucoinSample/operateUcoinSampleUpdate`, payload,{
          ...header
        })
    }
    //礼品管理 上下架 
    giftRedemptionManage.operateUcoinSampleIsUp = (payload) => {
        return ajaxinstance.post(`cms/ucoinSample/operateUcoinSampleIsUp`, payload,{
          ...header
        })
    }
    //礼品管理 上下架 
    giftRedemptionManage.operateUcoinOrderListByFilter = (payload) => {
        return ajaxinstance.post(`cms/ucoinSample/operateUcoinOrderListByFilter`, payload,{
          ...header
        })
    }
    return {
        giftRedemptionManage
    }
  }
  export default giftRedemptionCreate
  
import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const OrderList = Loadable({
  loader: () => import('./order-list'),
  loading: Loading
})
const CouponList = Loadable({
  loader: () => import('./coupon-list'),
  loading: Loading
})
const LiveOrderList = Loadable({
  loader: () => import('./live-order-list'),
  loading: Loading
})
const DeliveryOrder = Loadable({
    loader: () => import('./delivery-order'),
    loading: Loading
})
const PrintOrderOne = Loadable({
  loader: () => import('./print-order-one'),
  loading: Loading
})
const PrintOrderAll = Loadable({
  loader: () => import('./print-order-all'),
  loading: Loading
})
export default (
  <React.Fragment>
    <Route path='/OrderList' component={OrderList} />
    <Route path='/CouponList' component={CouponList} />
    <Route path='/LiveOrderList' component={LiveOrderList} />
    <Route exact path='/DeliveryOrder' component={DeliveryOrder} />
    <Route exact path='/PrintOrderOne' component={PrintOrderOne} />
    <Route exact path='/PrintOrderAll' component={PrintOrderAll} />
  </React.Fragment>
)



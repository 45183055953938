const exchangeManageCreate = (ajaxinstance) => {
    let exchangeManage = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
    }
     // 兑换列表
     exchangeManage.queryExchangeList = (payload) => {
        return ajaxinstance.post(`cms/exchange/queryExchangeList`,payload,{
            ...header
        })
    }
    //兑换取消记录
    exchangeManage.queryExchangeCancel = (payload) => {
        return ajaxinstance.post(`cms/exchange/queryExchangeCancel`,payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }
        })
    }
    //兑换取消
    exchangeManage.operateExchangeCancel = (payload) => {
        return ajaxinstance.post(`cms/exchange/operateExchangeCancel`,payload,{
            ...header
        })
    }
    return {
        exchangeManage
    }
  }
  export default exchangeManageCreate
  
const feedbackCreate = (ajaxinstance) => {
    let feedbackManage = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
    }
     // 反馈列表
     feedbackManage.operateSuggestionList = (payload) => {
        return ajaxinstance.post(`cms/suggestion/operateSuggestionListByFilter`,payload,{
            ...header
        })
    }
    //详情
    feedbackManage.operateSuggestionById = (payload) => {
        return ajaxinstance.post(`cms/suggestion/operateSuggestionById`,payload,{
            ...header
        })
    }
    //意见反馈处理
    feedbackManage.operateSuggestionForUpdate = (payload) => {
        return ajaxinstance.post(`cms/suggestion/operateSuggestionForUpdate`,payload,{
            ...header
        })
    }
    return {
        feedbackManage
    }
  }
  export default feedbackCreate
  
import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const ProductList = Loadable({
  loader: () => import('./product-list'),
  loading: Loading
})
const ActiveUcoinProducts = Loadable({
    loader: () => import('./active-ucoin-products'),
    loading: Loading
  })
export default (
  <React.Fragment>
    <Route path='/ProductList' component={ProductList} />
    <Route path='/ActiveUcoinProducts' component={ActiveUcoinProducts} />
  </React.Fragment>
)

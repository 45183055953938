const customerCreate = (ajaxinstance) => {
  const customer = {}
  const header = {
    headers: {
        'content-Type':'application/json; charset=UTF-8'
    }
}
   // 登录
  customer.login = (payload) => {
    return ajaxinstance.post(`login`, payload,{
      headers: {
        'content-Type':'application/x-www-form-urlencoded'
      }
    })
  }
  // 退出登录
  customer.logout = (payload) => {
    return ajaxinstance.post(`logout`, payload,{
      headers: {
        'content-Type':'application/x-www-form-urlencoded'
      }
    })
  }
  // 获取侧边栏
  customer.showMenu = (userId) => {
    return ajaxinstance.get(`showMenu?userId=${userId}`)
  }
  //省接口
  customer.operatelistForProvince = () => {
    return ajaxinstance.post(`cms/store/operatelistForProvince`)
  }
  // 城市接口
  customer.operatelistCityByProvienceId = (payload) => {
    return ajaxinstance.post(`cms/store/operatelistCityByProvienceId`,payload,{
      ...header
    })
  }
  // 分类
   customer.getGoodsTypeInfo = (payload) => {
    return ajaxinstance.post(`cms/goodstype/getGoodsTypeInfo`,payload,{
      ...header
    })
  }
  // 获取门店的标签
  customer.geTabs = (payload) => {
    return ajaxinstance.post(`cms/store/geTabs`,payload,{
      ...header
    })
  }
  return {
    customer
  }
}
export default customerCreate

// 条码状态
function barCodeStatus(type) {
  switch (type) {
    case '0':
    case 0:
      return '已创建'
    case '1':
    case 1:
      return '已作废'
    case '2':
    case 2:
      return '已失效'
    case '3':
    case 3:
      return '已扫码'
    default:
      return ''
  }
}
// 条码状态
function state(type) {
  switch (type) {
    case '0':
    case 0:
      return '正常'
    case '1':
    case 1:
      return '作废'
    default:
      return ''
  }
}
// 性别
function sex(type) {
  switch (type) {
    case '1':
    case 1:
      return '男'
    case '2':
    case 2:
      return '女'
    default:
      return ''
  }
}
// 帐号状态
function accountStatus(type) {
  switch (type) {
    case '0':
    case 0:
      return '正常'
    case '1':
    case 1:
      return '冻结'
    default:
      return ''
  }
}
// 数据来源
function refer(type) {
  switch (type) {
    case '0':
    case 0:
      return '小程序'
    case '1':
    case 1:
      return '微信H5'
    case '2':
    case 2:
      return 'POS系统'
    case '3':
    case 3:
      return 'APP'
    default:
      return ''
  }
}
// u币活动产品状态
function activityState(type) {
  switch (type) {
    case '0':
    case 0:
      return '待生效'
    case '1':
    case 1:
      return '生效中'
    case '2':
    case 2:
      return '已过期'
    case '3':
    case 3:
      return '已作废'
    default:
      return ''
  }
}
//店铺类型
function storeType(type) {
  switch (type) {
    case '1':
    case 1:
      return '路面店'
    case '2':
    case 2:
      return '商城店'
    default:
      return ''
  }
}
// 是否样板店isTemplet
function isTemplet(type) {
  switch (type) {
    case '0':
    case 0:
      return '非样板店'
    case '1':
    case 1:
      return '样板店'
    default:
      return ''
  }
}
// 是否有平板pos
function isPad(type) {
  switch (type) {
    case '0':
    case 0:
      return '没有'
    case '1':
    case 1:
      return '有'
    default:
      return ''
  }
}
// 存续状态
function openState(type) {
  switch (type) {
    case '0':
    case 0:
      return '开'
    case '1':
    case 1:
      return '关'
    default:
      return '开'
  }
}
// 反馈  处理状态
function feedbackStatus(type) {
  switch (type) {
    case '0':
    case 0:
      return '未处理'
    case '1':
    case 1:
      return '已处理'
    default:
      return ''
  }
}
// 审核状态
function auditsStatus(type) {
  switch (type) {
    case '0':
    case 0:
      return '待审核'
    case '1':
    case 1:
      return '已通过'
    case '2':
    case 2:
      return '不通过'
    default:
      return ''
  }
}
// 会员类型
function memberLevelId(type) {
  switch (type) {
    case '1':
    case 1:
      return '悦薇粉丝'
    case '2':
    case 2:
      return '银卡会员'
    case '3':
    case 3:
      return '金卡会员'
    case '4':
    case 4:
      return '钻石会员'
    default:
      return ''
  }
}

// 上下架
function isUp(type) {
  switch (type) {
    case '0':
    case 0:
      return '下架'
    case '1':
    case 1:
      return '上架'
    default:
      return ''
  }
}
// 预订状态
function bookStatus(type) {
  switch (type) {
    case '0':
    case 0:
      return '待发货'
    case '1':
    case 1:
      return '待收货'
    case '2':
    case 2:
      return '已完成'
    case '3':
    case 3:
      return '已取消'
    default:
      return ''
  }
}
// 兑换记录状态
function exchangeRecordStatus(type) {
  switch (type) {
    case '0':
    case 0:
      return '待领取'
    case '1':
    case 1:
      return '已领取'
    case '2':
    case 2:
      return '已过期'
    default:
      return ''
  }
}
//商品状态
function goodsStatus(type) {
  switch (type) {
    case '1':
    case 1:
      return '已上架'
    case '0':
    case 0:
      return '待上架'
    case '2':
    case 2:
      return '已下架'
    default:
      return ''
  }
}
// 礼品状态
function giftStatus(type) {
  switch (type) {
    case '0':
    case 0:
      return '已领取'
    case '1':
    case 1:
      return '已使用'
    case '2':
    case 2:
      return '已过期'
    default:
      return ''
  }
}
//所有banner管理
// 小程序 banner管理  资源类型
function weiBannerType(type) {
  switch (type) {
    case '1':
    case 1:
      return '内链'
    case '2':
    case 2:
      return '外链'
    default:
      return ''
  }
}
function weiBannerFrom(type) {
  switch (type) {
    case '0':
      case 0:
        return '首页banner'
    case '1':
    case 1:
      return '商城banner'
    case '2':
    case 2:
      return '首页开屏'
    default:
      return ''
  }
}
// banner管理  状态
function uBannerStatus(type) {
  switch (type) {
    case '2':
    case 2:
      return '下架'
    case '1':
    case 1:
      return '上架'
    default:
      return ''
  }
}
// banner管理  状态
function wxBannerStatus(type) {
  switch (type) {
    case '3':
    case 3:
      return '下架'
    case '2':
    case 2:
      return '待上架'
    case '1':
    case 1:
      return '上架'
    default:
      return ''
  }
}
// u币商城  banner管理  资源类型
function uBannerType(type) {
  switch (type) {
    case '1':
    case 1:
      return '积分商城商品'
    case '2':
    case 2:
      return '积分商城礼券'
    case '3':
    case 3:
      return '外链'
    default:
      return ''
  }
}
// u币商城  banner管理  资源类型
function mode(type) {
  switch (type) {
    case '0':
    case 0:
      return '扫码'
    case '1':
    case 1:
      return '签到'
    case '2':
    case 2:
      return '兑换'
    case '3':
    case 3:
      return '活动赠送'
    case '4':
    case 4:
      return '欢迎礼'
    case '5':
    case 5:
      return '生日礼'
    case '6':
    case 6:
      return '分享'
    case '7':
    case 7:
      return '关注公众号'
    case '8':
    case 8:
      return '已扫码任务'
    case '9':
    case 9:
      return 'h5分享'
    case '10':
    case 10:
      return '绑定门店'
    case '11':
    case 11:
      return '会员资料完成'
    case '12':
    case 12:
      return 'APP分享'
    case '13':
    case 13:
      return '积分商城'
    case '14':
    case 14:
      return '活动'
    default:
      return ''
  }
}
//u币商城 增加 减少
function changeAddType(type) {
  switch (type) {
    case '0':
    case 0:
      return '增加'
    case '1':
    case 1:
      return '减少'
    default:
      return ''
  }
}
//兑换管理  兑换状态
function exchangeStatus(type) {
  switch (type) {
    case '1':
    case 1:
      return '已取消'
    case '0':
    case 0:
      return '兑换成功'
    default:
      return ''
  }
}
//兑换管理  兑换类型
function exchangeType(type) {
  switch (type) {
    case '1':
    case 1:
      return '商品'
    case '2':
    case 2:
      return '礼券'
    default:
      return ''
  }
}
//兑换管理  资源状态（商品类型）
function resourcesStatusOfGoods(type) {
  switch (type) {
    case '1':
    case 1:
      return '未使用'
    case '2':
    case 2:
      return '未使用'
    case '3':
    case 3:
      return '已取消'
    case '4':
    case 4:
      return '已使用'
    case '5':
    case 5:
      return '已过期'
    default:
      return ''
  }
}
//兑换管理  资源状态（商品类型）
function liveStatusOfGoods(type) {
  switch (type) {
    case '0':
    case 0:
      return '待支付'
    case '1':
    case 1:
      return '待使用'
    case '3':
    case 3:
      return '已取消'
    case '4':
    case 4:
      return '已完成'
    case '5':
    case 5:
      return '已过期'
    default:
      return ''
  }
}
function resourcesStatusOfGift(type) {
  switch (type) {
    case '0':
    case 0:
      return '已关闭'
    case '1':
    case 1:
      return '待使用'
    case '2':
    case 2:
      return '已使用'
    case '3':
    case 3:
      return '已过期'
    default:
      return ''
  }
}
//中奖纪录管理  奖品类型
function prizeType(type) {
  switch (type) {
    case '1':
    case 1:
      return '商品'
    case '2':
    case 2:
      return '礼券'
    case '3':
    case 3:
      return '积分'
    default:
      return ''
  }
}
function isPushMsg(is) {
  switch (is) {
    case 1:
      return '是'
    default:
      return '否'
  }
}
// 预约到店
function bookStoreStatus(is) {
  switch (is) {
    case 0:
    case '0':
      return '已预约'
    case 1:
    case '1':
      return '已到店'
    case 3:
    case '3':
      return '已取消'
    case 4:
    case '4':
      return '已过期'
    default:
      return ''
  }
}
// 置顶类型
function topCategory(is) {
  switch (is) {
    case 0:
    case '0':
      return '无'
    case 1:
    case '1':
      return '首页推荐'
    case 2:
    case '2':
      return '悠莱推荐'
    default:
      return ''
  }
}
// APP
function dataType(is) {
  switch (is) {
    case 0:
    case '0':
      return '全部'
    case 1:
    case '1':
      return '悦薇'
    case 2:
    case '2':
      return 'APP'
    default:
      return ''
  }
}
// APP
function dataSendType(is) {
  switch (is) {

    case 1:
    case '1':
      return '全部用户'
    case 2:
    case '2':
      return '指定用户'
    case 3:
    case '3':
      return '悦薇粉丝'
    case 4:
    case '4':
      return '银卡会员'
    case 5:
    case '5':
      return '金卡会员'
      case 6:
    case '6':
      return '钻石会员'
    default:
      return ''
  }
}
function goodsType(is) {
  switch (is) {
    case 1:
    case '1':
      return '实物礼品'
    case 6:
    case '6':
      return '周边商品'
    default:
      return ''
  }
}
export default {
  barCodeStatus,
  accountStatus,
  activityState,
  feedbackStatus,
  storeType,
  isTemplet,
  openState,
  auditsStatus,
  isPad,
  state,
  bookStatus,
  sex,
  exchangeRecordStatus,
  refer,
  isUp,
  memberLevelId,
  uBannerStatus,
  uBannerType,
  goodsStatus,
  giftStatus,
  weiBannerType,
  weiBannerFrom,
  mode,
  exchangeStatus,
  exchangeType,
  resourcesStatusOfGoods,
  resourcesStatusOfGift,
  liveStatusOfGoods,
  prizeType,
  wxBannerStatus,
  isPushMsg,
  bookStoreStatus,
  topCategory,
  dataType,
  changeAddType,
  dataSendType,
  goodsType
}

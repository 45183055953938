import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const GiftManage = Loadable({
  loader: () => import('./gift-manage'),
  loading: Loading
})
const RedemptionRecordManage = Loadable({
  loader: () => import('./redemption-record-manage'),
  loading: Loading
})
export default (
  <React.Fragment>
    <Route exact path='/GiftManage' component={GiftManage} />
    <Route exact path='/RedemptionRecordManage' component={RedemptionRecordManage} />
  </React.Fragment>
)

import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const BannerMainManage = Loadable({
  loader: () => import('./banner-main-manage'),
  loading: Loading
})
const HotContentManage = Loadable({
    loader: () => import('./hot-content-manage'),
    loading: Loading
  })
  const HotProductManage = Loadable({
    loader: () => import('./hot-product-manage'),
    loading: Loading
  })
  const NewPromotionManage = Loadable({
    loader: () => import('./new-promotion-manage'),
    loading: Loading
  }) 
  const AppIntroductoryPage = Loadable({
    loader: () => import('./introductory-page-manage'),
    loading: Loading
  }) 
export default (
  <React.Fragment>
    <Route path='/BannerMainManage' component={BannerMainManage} />
    <Route path='/HotContentManage' component={HotContentManage} />
    <Route path='/HotProductManage' component={HotProductManage} />
    <Route path='/NewPromotionManage' component={NewPromotionManage} />
    <Route path='/AppIntroductoryPage' component={AppIntroductoryPage} />
  </React.Fragment>
)

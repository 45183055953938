// 添加key
const addKeys = (list, str = '') => list && list.map((item, i) => { item.key = i + str; return item })
// 添加字段
const addParams = (list, par, str = '') => list && list.map((item, i) => {
  item[par] = str
  return item
})
export default {
  addParams,
  addKeys
}

const activityCrate = (ajaxinstance) => {
	const header = {
		headers: {
			'content-Type': 'application/json; charset=UTF-8'
		}
	}
	let activityManage = {}

	// 活动列表
	activityManage.activityList = (payload) => {
		return ajaxinstance.post(`cms/activity/getActivityList`, payload, {
			...header
		})
	}
	// 活动add 
	activityManage.saveActivity = (payload) => {
		return ajaxinstance.post(`cms/activity/saveActivity`, payload, {
			...header
		})
	}
	// 活动编辑
	activityManage.updateActivity = (payload) => {
		return ajaxinstance.post(`cms/activity/updateActivity`, payload, {
			...header
		})
	}

	
	// 活动列表 - 投诉反馈
	activityManage.activityFeedbackList = (payload) => {
		return ajaxinstance.post(`cms/vote/getVoteComplaintList`, payload, {
			...header
		})
	}
	// 活动列表 - 投票上墙信息
	activityManage.activityVoteInfo = (payload) => {
		return ajaxinstance.post(`cms/vote/getVoteInfo`, payload, {
			...header
		})
	}
	// 活动列表 - 投票上墙列表
	activityManage.activityVoteList = (payload) => {
		return ajaxinstance.post(`cms/vote/getParticipationList`, payload, {
			...header
		})
	}
	// 活动列表 - 投票上墙上下架
	activityManage.activityVoteUpdate = (payload) => {
		return ajaxinstance.post(`cms/vote/updateVoteParticipation`, payload, {
			...header
		})
	}
	// 添加活动 - 普通活动
	activityManage.activityAdd = (payload) => {
		return ajaxinstance.post(`cms/commonActivity/commonActivityInfoSave`, payload, {
			...header
		})
	}
	// 添加活动 - 问券调查
	activityManage.activityAddVoucher = (payload) => {
		return ajaxinstance.post(`cms/questionnaire/saveQuestionnaireActivity`, payload, {
			...header
		})
	}
	// 添加活动 - 投票上墙
	activityManage.activityAddVote = (payload) => {
		return ajaxinstance.post(`cms/vote/saveVote`, payload, {
			...header
		})
	}
	// 活动更新
	activityManage.activityUpdate = (payload) => {
		return ajaxinstance.post(`cms/activity/updateActivity`, payload, {
			...header
		})
	}
	// 活动参与审核-活动信息
	activityManage.activityInfo = (payload) => {
		return ajaxinstance.post(`cms/commonActivity/getActivityEnterInfo`, payload, {
			...header
		})
	}
	// 活动参与审核-参与人列表
	activityManage.activityPartakeList = (payload) => {
		return ajaxinstance.post(`cms/commonActivity/getActivityParticipationList`, payload, {
			...header
		})
	}
	// 活动参与人列表 - 驳回/通过
	activityManage.activityPartakeManage = (payload) => {
		return ajaxinstance.post(`cms/commonActivity/updateActivityParticipation`, payload, {
			...header
		})
	}
	// 问券调查 - 问券统计-详情
	activityManage.activityVoucherInfo = (payload) => {
		return ajaxinstance.post(`cms/questionnaire/getQuestionnaireVo`, payload, {
			...header
		})
	}
	// 问券调查 - 问券统计-列表
	activityManage.activityVoucherList = (payload) => {
		return ajaxinstance.post(`cms/questionnaire/getQuestionnaireStatistics`, payload, {
			...header
		})
	}
	// 普通活动详情 -获取
	activityManage.getActivityDetail = (payload) => {
		return ajaxinstance.post(`cms/activity/getCommonActivityInfo`, payload, {
			...header
		})
	}
	// 投票上墙详情 -获取
	activityManage.getVoteDetail = (payload) => {
		return ajaxinstance.post(`cms/vote/getVoteInfo`, payload, {
			...header
		})
	}
	// 投票上墙详情 -回复
	activityManage.getVoteFeedBack = (payload) => {
		return ajaxinstance.post(`cms/vote/updateVoteComplaint`, payload, {
			...header
		})
	}

	activityManage.saveVoteParticipation = (payload) => {
		return ajaxinstance.post(`cms/vote/saveVoteParticipation`, payload, {
			...header
		})
	}

	activityManage.getParticipationInfo = (payload) => {
		return ajaxinstance.post(`cms/vote/getParticipationInfo`, payload, {
			...header
		})
	}


	activityManage.operateVoteInfoByFilter = (payload) => {
		return ajaxinstance.post(`cms/vote/operateVoteInfoByFilter`, payload, {
			...header
		})
	}


	// 问券调查详情 -获取
	activityManage.getVoucherDetail = (payload) => {
		return ajaxinstance.post(`cms/questionnaire/getQuestionnaireInfo`, payload, {
			...header
		})
	}
	// 普通活动详情 -编辑
	activityManage.editActivityDetail = (payload) => {
		return ajaxinstance.post(`cms/commonActivity/updateCommonActivityInfo`, payload, {
			...header
		})
	}
	// 投票上墙 -编辑
	activityManage.editVoteDetail = (payload) => {
		return ajaxinstance.post(`cms/vote/updateVote`, payload, {
			...header
		})
	}
	// 问券调查 -编辑
	activityManage.editVoucherDetail = (payload) => {
		return ajaxinstance.post(`cms/questionnaire/updateQuestionnaireInfo`, payload, {
			...header
		})
	}
	//base6上传图片
	activityManage.generateImage = (payload) => {
		return ajaxinstance.post(`cms/commonActivity/generateImage`, payload, {
			...header
		})
	}
	//上传图片  /act/vote/operateVoteInfoById
	activityManage.photoUpload = (payload) => {
		return ajaxinstance.post(`cms/commonActivity/photoUpload`, payload, {
			...header
		})
	}
	activityManage.operateComplaintListByFilter = (payload) => {
		return ajaxinstance.post(`cms/complaint/operateComplaintListByFilter`, payload, {
			...header
		})
	}
	activityManage.operateSaveOrUpdateVote = (payload) => {
		return ajaxinstance.post(`cms/vote/operateSaveOrUpdateVote`, payload, {
			...header
		})
	}
	///////act/ActivityStore/operateActivityStoresdeleteOrInsertByStoreId
	activityManage.operateVoteInfoById = (payload) => {
		return ajaxinstance.post(`cms/vote/operateVoteInfoById`, payload, {
			...header
		})
	}
		activityManage.operateActivityStoresdeleteOrInsertByStoreId = (payload) => {
			return ajaxinstance.post(`cms/ActivityStore/operateActivityStoresdeleteOrInsertByStoreId`, payload, {
				...header
			})
		}


	activityManage.operateActivityStoresInfoByActivityId = (payload) => {
			return ajaxinstance.post(`cms/ActivityStore/operateActivityStoresInfoByActivityId`, payload, {
				...header
			})
		}


	activityManage.operateActivityStoreInfoSave = (payload) => {
		return ajaxinstance.post(`cms/ActivityStore/operateActivityStoreInfoSave`, payload, {
			...header
		})
	}

	activityManage.operateComplaintStatus = (payload) => {
		return ajaxinstance.post(`cms/complaint/operateComplaintStatus`, payload, {
			...header
		})
	}

	activityManage.operateParticipationInfoListByFilter = (payload) => {
		return ajaxinstance.post(`cms/vote/operateVoteParticipationList`, payload, {
			...header
		})
	}
	activityManage.operateParticipationInfoStatus = (payload) => {
		return ajaxinstance.post(`cms/participation/operateParticipationInfoStatus`, payload, {
			...header
		})
	}

	activityManage.getReviewActivityPage = (payload) => {
		return ajaxinstance.post(`cms/commonActivity/getReviewActivityPage`, payload, {
			...header
		})
	}
	activityManage.updateReviewInfo = (payload) => {
		return ajaxinstance.post(`cms/commonActivity/updateReviewInfo`, payload, {
			...header
		})
	}
	activityManage.queryCommodityList = (payload) => {
	 	return ajaxinstance.post(`cms/commodity/queryCommodityList`, payload, {
	 		...header
	 	})
	 }

	  //礼券列表 列表 act/commonActivity/getReviewInfo
	  activityManage.queryCardList = (payload) => {
	  	return ajaxinstance.post(`cms/card/queryCardList`, payload, {
	  		...header
	  	})
		}

	 activityManage.getReviewInfo = (payload) => {
	 	return ajaxinstance.post(`cms/commonActivity/getReviewInfo`, payload, {
	 		...header
	 	})
	 }
	 activityManage.delEnter = (payload) => {
	 	return ajaxinstance.post(`cms/commonActivity/delEnter`, payload, {
	 		...header
	 	})
	 }
	 activityManage.delParticipation = (payload) => {
	 	return ajaxinstance.post(`cms/vote/delParticipation`, payload, {
	 		...header
		 })
	 }
	 activityManage.delGoodsOrIssue = (payload) => {
	 	return ajaxinstance.post(`cms/questionnaire/delGoodsOrIssue`, payload, {
	 		...header
	 	})
	 }
	 //act/vote/delParticipation
	return {
		activityManage
	}
}

export default activityCrate

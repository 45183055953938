import React from 'react'
import { Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const BannerManage = Loadable({
  loader: () => import('./banner-manage'),
  loading: Loading
})
const MallCategories = Loadable({
  loader: () => import('./mall-categories'),
  loading: Loading
})
const MallGoodsList = Loadable({
  loader: () => import('./mall-goods-list'),
  loading: Loading
})
const MallGiftList = Loadable({
  loader: () => import('./mall-gift-list'),
  loading: Loading
})
const Top = Loadable({
  loader: () => import('./top'),
  loading: Loading
})
export default (
  <React.Fragment>
    <Route path='/BannerManage' component={BannerManage} />
    <Route path='/MallCategories' component={MallCategories} />
    <Route path='/MallGoodsList' component={MallGoodsList} />
    <Route path='/MallGiftList' component={MallGiftList} />
    <Route path='/Top' component={Top} />
  </React.Fragment>
)

const liveGoodsList = (ajaxinstance) => {
    let liveStore = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
     }
     // 商品列表
     liveStore.liveGoodsList = (payload) => {
        return ajaxinstance.post(`cms/ecGoods/queryGoodsList`,payload,{
            ...header
        })
    }
    return {
        liveStore
    }
  }
  export default liveGoodsList
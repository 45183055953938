const productCreate = (ajaxinstance) => {
    const productInfoSync = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
     }
     // 产品列表
     productInfoSync.productList = (payload) => {
        return ajaxinstance.post(`cms/product/list`,payload,{
            ...header
          })
    }
    //新增产品
    productInfoSync.addProduct = (payload) => {
        return ajaxinstance.post(`cms/product/operateProductSave`, payload,{
            ...header
        })
    }
     //获取单条数据
     productInfoSync.findProductById = (payload) => {
        return ajaxinstance.post(`cms/product/findProductById`, payload)
    }
     //编辑
     productInfoSync.updateProduct = (payload) => {
        return ajaxinstance.post(`cms/product/operateProductUpdate`, payload,{
            ...header
        })
    }
    //删除产品
    productInfoSync.deleteProductById = (payload) => {
        return ajaxinstance.post(`cms/product/operateProductDelete`, payload,{
            ...header
        })
    }
    // 积分活动产品列表
    productInfoSync.operateActivityProductListByFilter = (payload) => {
        return ajaxinstance.post(`cms/activityProduct/operateActivityProductListByFilter`, payload,{
            ...header
        })
    }
    // 添加U币活动产品
    productInfoSync.addActivityProductSave = (payload) => {
        return ajaxinstance.post(`cms/activityProduct/operateActivityProductSave`, payload,{
            ...header
        })
    }
    //获取U币活动产品详情
    productInfoSync.operateActivityProductById = (payload) => {
        return ajaxinstance.post(`cms/activityProduct/operateActivityProductById`, payload,{
            ...header
        })
    }
    // 编辑活动产品
    productInfoSync.operateActivityProductUpdate = (payload) => {
        return ajaxinstance.post(`cms/activityProduct/operateActivityProductUpdate`, payload,{
            ...header
        })
    }
    //作废
    productInfoSync.operateActivityProductGive = (payload) => {
        return ajaxinstance.post(`cms/activityProduct/operateActivityProductGive`, payload,{
            ...header
        })
    }
    // 删除
    productInfoSync.operateActivityProductDelete = (payload) => {
        return ajaxinstance.post(`cms/activityProduct/operateActivityProductDelete`, payload,{
            ...header
        })
    }
     // 删除
     productInfoSync.synProductByCode = (payload) => {
        return ajaxinstance.post(`cms/product//synProductByCode`, payload,{
            ...header
        })
    }
    return {
        productInfoSync
    }
  }
  export default productCreate
  
const enjoyCrate = (ajaxinstance) => {
	const header = {
		headers: {
			'content-Type': 'application/json; charset=UTF-8'
		}
	}
	let enjoyManage = {}
	enjoyManage.getDrawLotteryListPage = (payload) => {
		return ajaxinstance.post(`act/drawLottery/getDrawLotteryListPage`, payload, {
			...header
		})
	}
		enjoyManage.checkDrawLottery = (payload) => {
			return ajaxinstance.post(`act/drawLottery/checkDrawLottery`, payload, {
				...header
			})
		}
		// 中奖纪录管理  分页
			enjoyManage.getRecordListPage = (payload) => {
				return ajaxinstance.post(`act/drawLottery/getRecordListPage`, payload, {
					...header
				})
			}
		// 中奖纪录管理  编辑收货地址
		enjoyManage.updateGatherCargo = (payload) => {
			return ajaxinstance.post(`act/drawLottery/updateGatherCargo`, payload, {
				...header
			})
		}
		enjoyManage.saveDisposeInfo = (payload) => {
			return ajaxinstance.post(`act/drawLottery/saveDisposeInfo`, payload, {
				...header
			})
		}
		enjoyManage.updateDrawLottery = (payload) => {
			return ajaxinstance.post(`act/drawLottery/updateDrawLottery`, payload, {
				...header
			})
		}
		enjoyManage.checkRecordInfo = (payload) => {
			return ajaxinstance.post(`act/drawLottery/checkRecordInfo`, payload, {
				...header
			})
		}
		enjoyManage.deletePrize = (payload) => {
			return ajaxinstance.post(`act/drawLottery/deletePrize`, payload, {
				...header
			})
		}
		enjoyManage.deleteDrawLottery = (payload) => {
			return ajaxinstance.post(`act/drawLottery/deleteDrawLottery`, payload, {
				...header
			})
		}
		enjoyManage.saveDrawLottery = (payload) => {
			return ajaxinstance.post(`act/drawLottery/saveDrawLottery`, payload, {
				...header
			})
		}
		enjoyManage.updateShows = (payload) => {
			return ajaxinstance.post(`act/drawLottery/updateShows`, payload, {
				...header
			})
		}
			enjoyManage.deletePrize = (payload) => {
				return ajaxinstance.post(`act/drawLottery/deletePrize`, payload, {
					...header
				})
			}

		/////////act/questionnaire/delGoodsOrIssue
	return {
		enjoyManage
	}
}

export default enjoyCrate

const discoveryManageCreate = (ajaxinstance) => {
    let discoveryManage = {}
    const header = {
        headers: {
            'content-Type':'application/json; charset=UTF-8'
        }
    }
     // 发现列表
    discoveryManage.queryDiscoveryList = (payload) => {
        return ajaxinstance.post(`cms/discovery/queryDiscoveryList`,payload,{
            ...header
        })
    }
    //屏蔽发现
    discoveryManage.operateDiscoveryShield = (payload) => {
        return ajaxinstance.post(`cms/discovery/operateDiscoveryShield`,payload,{...header})
    }
    //删除发现
    discoveryManage.operateDiscoveryDelete = (payload) => {
            return ajaxinstance.post(`cms/discovery/operateDiscoveryDelete`,payload,{...header})
    }
    //新增发现
    discoveryManage.operateDiscoverySave = (payload) => {
        return ajaxinstance.post(`cms/discovery/operateDiscoverySave`,payload, {
            headers:{
            'content-Type':'application/x-www-form-urlencoded'
        }})
    }
    //根据id查询当前发现详情
    discoveryManage.operateDiscoveryById = (payload) => {
        return ajaxinstance.post(`cms/discovery/operateDiscoveryById`,payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }})
    }
    //查询热门搜索列表
    discoveryManage.queryHotSearchList = (payload) => {
        return ajaxinstance.post(`cms/discoveryHotSearch/queryHotSearchList`,payload,{...header})
    }
    //删除热门搜索
    discoveryManage.operateHotSearchDelete = (payload) => {
        return ajaxinstance.post(`cms/discoveryHotSearch/operateHotSearchDelete`,payload,{...header})
    }
    //新增热门搜索
    discoveryManage.operateHotSearchSave = (payload) => {
        return ajaxinstance.post(`cms/discoveryHotSearch/operateHotSearchSave`,payload,{...header})
    }
    //编辑热门搜索
    discoveryManage.operateHotSearchUpdate = (payload) => {
        return ajaxinstance.post(`cms/discoveryHotSearch/operateHotSearchUpdate`,payload,{...header})
    }
    //查询标签列表
    discoveryManage.queryDiscoveryLabelList = (payload) => {
        return ajaxinstance.post(`cms/discoveryLabel/queryDiscoveryLabelList`,payload,{...header})
    }
    //删除标签
    discoveryManage.operateDiscoveryLabelDelete = (payload) => {
        return ajaxinstance.post(`cms/discoveryLabel/operateDiscoveryLabelDelete`,payload,{...header})
    }
    //新增标签
    discoveryManage.operateDiscoveryLabelSave = (payload) => {
        return ajaxinstance.post(`cms/discoveryLabel/operateDiscoveryLabelSave`,payload,{...header})
    }
    //编辑标签
    discoveryManage.operateDiscoveryLabelUpdate = (payload) => {
        return ajaxinstance.post(`cms/discoveryLabel/operateDiscoveryLabelUpdate`,payload,{...header})
    }
    //查询发现评论列表
    discoveryManage.queryDiscoveryCommentList = (payload) => {
        return ajaxinstance.post(`cms/discoveryComment/queryDiscoveryCommentList`,payload,{...header})
    }
    //根据id查询当前发现评论详情
    discoveryManage.operateDiscoveryCommentById = (payload) => {
        return ajaxinstance.post(`cms/discoveryComment/operateDiscoveryCommentById`,payload,{
            headers:{
                'content-Type':'application/x-www-form-urlencoded'
            }})
    }
    //屏蔽评论
    discoveryManage.shieldDiscoveryCommentById = (payload) => {
        return ajaxinstance.post(`cms/discoveryComment/shieldDiscoveryCommentById`,payload,{ headers:{
            'content-Type':'application/x-www-form-urlencoded'
        }})
    }
    //举报管理  列表
    discoveryManage.queryDiscoveryAccusationList = (payload) => {
        return ajaxinstance.post(`cms/discoveryAccusation/queryDiscoveryAccusationList`,payload,{...header})
    }
    //删除举报
    discoveryManage.deleteDiscoveryAccusationById = (payload) => {
        return ajaxinstance.post(`cms/discoveryAccusation/deleteDiscoveryCommentById`,payload,{headers:{
            'content-Type':'application/x-www-form-urlencoded'
        }})
    }
    //根据id查询当前发现举报详情
    discoveryManage.operateDiscoveryAccusationById = (payload) => {
        return ajaxinstance.post(`cms/discoveryAccusation/operateDiscoveryAccusationById`,payload,{...header})
    }
    //屏蔽举报
    discoveryManage.shieldDiscoveryAccusationById = (payload) => {
        return ajaxinstance.post(`cms/discoveryAccusation/shieldDiscoveryAccusationById`,payload,{headers:{
            'content-Type':'application/x-www-form-urlencoded'
        }})
    }
    //查询特殊显示列表
    discoveryManage.queryDiscoverySpecialList = (payload) => {
        return ajaxinstance.post(`cms/discoverySpecial/queryDiscoverySpecialList`,payload,{...header})
    }
	//根据id查询当前特殊显示详情
    discoveryManage.operateDiscoverySpecialById = (payload) => {
        return ajaxinstance.post(`cms/discoverySpecial/operateDiscoverySpecialById`,payload,{headers:{
            'content-Type':'application/x-www-form-urlencoded'
        }})
    }
    //删除特殊显示
    discoveryManage.operateDiscoverySpecialDelete = (payload) => {
        return ajaxinstance.post(`cms/discoverySpecial/operateDiscoverySpecialDelete`,payload,{...header})
    }
    //新增特殊显示
    discoveryManage.operateDiscoverySpecialSave = (payload) => {
        return ajaxinstance.post(`cms/discoverySpecial/operateDiscoverySpecialSave`,payload,{...header})
    }
    //编辑特殊显示
    discoveryManage.operateDiscoverySpecialUpdate = (payload) => {
        return ajaxinstance.post(`cms/discoverySpecial/operateDiscoverySpecialUpdate`,payload,{...header})
    }
    return {
        discoveryManage
    }
  }
  export default discoveryManageCreate
  